import React from 'react';
import gql from 'graphql-tag';
import { useSubscription, ApolloProvider } from '@apollo/react-hooks';
import { getSpiderClient } from '../../util/gql/getSpiderClient';

// The results of this query are not currently used, we just
// need the subscription to trigger the front end to update
function TeamSettingsEvents({ onEvent }) {
  useSubscription(gql`
      subscription onMyTeamSettingsUpdated {
        onMyTeamSettingsUpdated {
          id
        }
      }
    `, {
    onSubscriptionData() {
      onEvent();
    },
    shouldResubscribe: true,
  });

  return null;
}

export default function (props) {
  return (
    <ApolloProvider client={getSpiderClient()}>
      <TeamSettingsEvents {...props} />
    </ApolloProvider>
  );
}

/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const NUDGE_ZOOM_GAP = 5;

const ZOOM_LEVELS = [
    25,
    50,
    75,
    100,
    150,
    200,
    400,
    1000,
];

const VIEW_CONTAINER_PADDING_TOP = 100;
const SMART_COMPARE_THRESHOLD = 0.001;
const HEADER_HEIGHT = 100;

const SMART_COMPARE_DIFF_SETTINGS_KEY = 'pageproof.app.smart-compare.diff-settings';
const DEFAULT_SMART_COMPARE_DIFF_SETTINGS = {
    diffColor: '#138b3b',
    ghostOpacity: 0.75,
    strobeSpeed: 0,
    jiggleDistance: 0,
};

class CompareProofController extends BaseProofController{
    // All the keypair cleanup functions (by proof ID)
    keypairCleanupFunctions = {};

    /**
     * Dialog type
     * @type {} object
     */
    dialog = null;

    /**
     * leftImage data Obj
     * @type {{}}
     */
    leftImageData = {};

    /**
     * rightImage data Obj
     * @type {{}}
     */
    rightImageData = {};

    /**
     * latest proof id
     * @type {null}
     */
    latestProofId = null;

    /**
     * videoPlayerCtrl for left
     * @type {{}}
     */
    leftVideoPlayer = {};

    /**
     * videoPlayerCtrl for right
     * @type {{}}
     */
    rightVideoPlayer = {};

    /**
     * selected view mode
     * @type {string | null}
     */
    compareViewMode = null;

    /**
     * comparison data Obj
     * @type {{}}
     */
    comparisonData = {};

    /**
     * Active proof during compareMode and merge status
     * @type {{}}
     */
    activeProofData = {};

    /**
     * proofData for a comment
     * @type {{}}
     */
    proof = {};

    /**
     * Flag for looking if still comparing
     * @type {boolean}
     */
    isComparing = false;

    /**
     * if comparison have made and comparisonData has been populated
     * @type {boolean}
     */
    compareMode = false;

    /**
     * if both left and right images are of same size
     * @type {boolean}
     */
    isSameSize = false;

    /**
     * if both left and right version's extension belongs to allowed Extensions
     * @type {boolean}
     */
    isAllowedExtensions = false;

    /**
     * Cached comparison data
     * @type []
     */
    comparisonStorage = [];

    /**
     * scroll mode
     * @type {string}
     */
    scrollMode = 'pan';

    storedZoom = false;
    storedRotation = 0;
    storedPosition = false;

    /**
     * if pin-comment has to be shown
     * @type {boolean}
     */
    showComments = false;

    /**
     * Both left-right canvases are linked or not
     * @type {boolean}
     */
    isLinked = true;

    showPins = true;

    /**
     * If comparing same proof versions or different proofs
     * @type {boolean}
     * */
    isSameProof = true;

    // If proofs have errors  use for requesting access
    proofLoadErrors = {};

    /**
     * selected canvas type
     * @type {string}
     */
    selectedCanvasType = null;

    currentCanvasPosition = {};

    /**
     * If image slider is currently active.
     */
    imgSliderActive = false;

    isActivatedRuler = false;
    
    isActivatedMarqueeZoom = false;

    leftPageDimensionInfo = {
        width: 0,
        height: 0,
        unit: null,
        isDecimal: false,
        scale: 1,
    };

    rightPageDimensionInfo = {
        width: 0,
        height: 0,
        unit: null,
        isDecimal: false,
        scale: 1,
    };

    sliderRightImg = {};

    sliderLeftImg = {};

    imgSlider = {};

    flashingGeneralComment = {
        left: null,
        right: null,
    };

    showSmartCompareDiffOverlay = false;

    static COMPARE_SCREEN_AREA_OPTIONS = {
        left: 'left',
        right: 'right',
        center: 'center'
    };

    get hasCommentsLoaded() {
        return this.leftImageData.commentsLoaded && this.rightImageData.commentsLoaded;
    }

    get isLoading() {
        return this.leftImageData.loading || this.rightImageData.loading || this.isComparing;
    }

    get commentCount() {
        return (
            (this.leftImageData &&
            this.leftImageData.comments &&
            this.leftImageData.comments.length)
            +
            (this.rightImageData &&
                this.rightImageData.comments &&
                this.rightImageData.comments.length)
        );
    }

    get privateCount() {
        return (
            (this.leftImageData &&
            this.leftImageData.comments &&
            this.leftImageData.comments.filter(comment => comment.isPrivate).length)
            +
            (this.rightImageData &&
                this.rightImageData.comments &&
                this.rightImageData.comments.filter(comment => comment.isPrivate).length)
        );
    }

    /**
     * @constructor
     * @ngInject
     */
    constructor () {
        super();

        this.$$import(this.$$dependencies([
            '$scope',
            'scopeService',
            '$rootScope',
            '$routeParams',
            '$location',
            '$timeout',
            '$interval',
            '$q',
            '$filter',
            'callbackService',
            'apiService',
            'appService',
            'UserService',
            'userService',
            'eventService',
            'proofInfoService',
            'deferVisibleService',
            'seoService',
            'backendService',
            'storageService',
            'fileService',
            'DataService',
            'proofRepositoryService',
            'commentRepositoryService',
            'PPProofComment',
            'tooltipService',
            'textCommentWithMentionFilter',
            'SegmentIo',
            'shortcutService',
            'PPCommentMessageType',
            'downloadManager',
            'localCommentService',
            'domService',
            'PPProofType',
            'PPProofStatus',
            'PPCompareProof',
            'PPCommentMarkType',
            'sdk'
        ]));

        if (window.isProofId(this.$$.$routeParams.param1) ||
            window.isBriefId(this.$$.$routeParams.param1)) {
            this.leftProofId = this.$$.$routeParams.param1;
        }
        if (window.isProofId(this.$$.$routeParams.param2) ||
            window.isBriefId(this.$$.$routeParams.param2)) {
            this.rightProofId = this.$$.$routeParams.param2;
            this.leftPage = 1;
            this.rightPage = 1;
            this.isSameProof = false;
        } else if (window.isProofId(this.$$.$routeParams.param3) ||
            window.isBriefId(this.$$.$routeParams.param3)) {
            this.rightProofId = this.$$.$routeParams.param3;
            this.leftPage = this.$$.$routeParams.param2;
            this.rightPage = this.$$.$routeParams.param4;
            this.isSameProof = false;
        }
        this.user = this.$$.userService.getUser();
        this.encryptionData = this.user.getEncryptionData();

        this.leftImageData = new this.$$.PPCompareProof(this.user, 'left', this.leftPage);
        this.rightImageData = new this.$$.PPCompareProof(this.user, 'right', this.rightPage);

        this.init();
        this.initWatchers();
        this.initKeyboardShortcuts();
        this.initCurrentCanvasPosition();
        this.beforeDestroy(() => this.cleanupKeyPairs());

        this.getCompareProofCtrl = () => {
            const that = this;
            const latestProofObj = that.getLatestProofObj();
            that.permissions = latestProofObj ? latestProofObj.permissions : null;
            return that;
        }

        this.scrubToThrottled = debounce(this.$$.eventService.$$wrap((time, player) => {
            player.scrub(time);
        }), 100);

        this.zoomProps = {
            onChange: type => level => this.zoomToCanvas(level, type),
        };

        this.toolProps = {
            switchPosition: {
                left: null,
                right: null,
                onSwitch: () => this.SwitchProofs(),
            },
        };

        const that = this;
        this.penToolProps = {
            get mode() {
                return that.interactionMode;
            },
            get modes() {
                return [
                    'pin',
                    that.isStatic(that.getLatestProofObj()) && 'draw',
                    'general',
                ].filter(Boolean);
            },
            get selected() {
                return that.isCommenting;
            },
            direction: 'right',
            constraints: { width: 40, height: 23 },
            size: 60,
            spacing: 15,
            onChangeMode(mode, selected) {
                that.interactionMode = mode;
                that.isCommenting = selected;
                that.onInteractionModeChanged();
            },
        };

        this.drawingControlsProps = {
            get canUndoDrawing() {
                return that.getLatestVersionCanvas().canUndoDrawing;
            },
            get canRedoDrawing() {
                return that.getLatestVersionCanvas().canRedoDrawing;
            },
            onUndoDrawing: () => that.getLatestVersionCanvas().undoDrawing(),
            onRedoDrawing: () => that.getLatestVersionCanvas().redoDrawing(),
            onDeleteDrawing: () => that.getLatestVersionCanvas().deleteDrawing(),
        }

        this.debouncedMatchBothCanvas = debounce(canvasType => this.matchBothCanvas(canvasType), 1);

        this.canvasContainerElements = {
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left]: document.getElementsByClassName('app__compare__left-container'),
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right]: document.getElementsByClassName('app__compare__right-container'),
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center]: document.getElementsByClassName('app__compare__center-container'),
        };

        this.parentContainerElement = {
            LEFT_CONTAINER: document.getElementsByClassName('app__compare__left'),
            RIGHT_CONTAINER: document.getElementsByClassName('app__compare__right')
        };

        this.imgComparisonSliderElement = document.getElementsByClassName('app__compare__img-comp-slider');

        const smartCompareDiffSettingsJson = localStorage.getItem(SMART_COMPARE_DIFF_SETTINGS_KEY);
        this.smartCompareDiffSettings = Object.assign(
            {}, // clone
            DEFAULT_SMART_COMPARE_DIFF_SETTINGS, // copy in the defaults (in case we add new ones)
            smartCompareDiffSettingsJson ? JSON.parse(smartCompareDiffSettingsJson) : {} // copy in the overrides
        );
    }

    onSmartCompareDiffSettingsChange = (settings) => {
        this.smartCompareDiffSettings = settings;
        localStorage.setItem(SMART_COMPARE_DIFF_SETTINGS_KEY, JSON.stringify(settings));
        this.renderSmartCompareDiffOverlays();
    }

    renderSmartCompareDiffOverlays() {
        if (this.centerCanvas) {
            this.renderSmartCompareDiffOverlay(this.centerCanvas);
        }
        if (this.leftCanvas) {
            this.renderSmartCompareDiffOverlay(this.leftCanvas);
        }
        if (this.rightCanvas) {
            this.renderSmartCompareDiffOverlay(this.rightCanvas);
        }
    }

    getLatestVersionCanvas() {
        return this.leftImageData.proofData.isLatestVersion ? this.leftCanvas : this.rightCanvas
    }

    onInteractionModeChanged() {
        this.togglePins(false);
        if (this.isCommenting) {
            switch (this.interactionMode) {
                case 'pin': {
                    break;
                }
                case 'draw': {
                    this.enableDrawing();
                    break;
                }
                case 'general': {
                    const latestVersionObj = this.leftImageData.proofData.isLatestVersion ? this.leftImageData : this.rightImageData;
                    this.startCreateComment(null, latestVersionObj);
                    break;
                }
            }
        }
    }

    enableDrawing() {
        if (this.leftCanvas) this.leftCanvas.enableDrawing();
        if (this.rightCanvas) this.rightCanvas.enableDrawing();
    }

    resetDrawing() {
        if (this.leftCanvas) this.leftCanvas.resetDrawing();
        if (this.rightCanvas) this.rightCanvas.resetDrawing();
    }

    updateTypeOfVersions(versionsArr, typeToBe) {
        versionsArr.forEach(version => (version.type = typeToBe));
    }

    SwitchProofs = () => {
        this.leftImageData.proofSwitched = true;
        this.rightImageData.proofSwitched = true;
        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();

        [this.leftImageData, this.rightImageData] = [this.rightImageData, this.leftImageData];
        this.leftImageData.type = 'left';
        this.rightImageData.type = 'right';

        this.updateTypeOfVersions(this.leftImageData.allowedVersions, 'left');
        this.updateTypeOfVersions(this.rightImageData.allowedVersions, 'right');

        this.updateToolProps(this.leftImageData);
        this.updateToolProps(this.rightImageData);

        if (this.isVideoOrAudio(this.leftImageData)) {
            this.updateVideoPlayerSource(this.leftImageData);
        }

        if (this.isVideoOrAudio(this.rightImageData)) {
            this.updateVideoPlayerSource(this.rightImageData);
        }
    }

    scrubTo(time, player) {
        player.moveSeek(time);
        this.scrubToThrottled(time, player);
        player.pause();
    }

    play(versionObj) {
        if (this.isLinked && this.areVideos()) {
            this.toggleBothPlayers();
        } else if (versionObj.proofData.video) {
            this.getPlayerType(versionObj).toggle();
        } else {
            this.goToPage(versionObj);
        }
    }

    updatePageDimensionInfoLeft = (data) => {
        this.leftPageDimensionInfo = Object.assign({}, this.leftPageDimensionInfo, data);
    }

    updatePageDimensionInfoRight = (data) => {
        this.rightPageDimensionInfo = Object.assign({}, this.rightPageDimensionInfo, data);
    }

    toggleBothPlayers(pause) {
        if (this.leftVideoPlayer.isReady && this.rightVideoPlayer.isReady) {
            if (this.leftVideoPlayer.isPlaying || pause) {
                this.leftVideoPlayer.pause();
                this.rightVideoPlayer.pause();
            } else {
                this.leftVideoPlayer.play();
                this.rightVideoPlayer.play();
            }
        }
    }

    isPinVisible (comment, currentTime) {
        return (
            (currentTime >= comment.time - .250) && // (quarter of a second because of player time precision)
            (currentTime <= comment.time + comment.duration)
        );
    }

    setScrubbers(versionObj) {
        const that = this;
        versionObj.props = {
            scrubber: {
                scrubTo: time => that.scrubTo(time, that.getPlayerType(versionObj)),
                updateCommentDuration: (commentIndex, newDuration) => {
                    return this.updateCommentDuration(versionObj, commentIndex, newDuration);
                },
                selectComment: comment => this.selectComment(comment, true),
                scrubToPin: (pin) => {
                    that.handleScrubToPin(pin, versionObj);
                },
                getPinColor: (isDone, isTodo) => this.getPinColor({ isDone, isTodo }, versionObj.proofData),
                get comments() {
                    if (versionObj.comments) {
                        return versionObj.comments;
                    }
                },
                get duration() {
                    let player = that.getPlayerType(versionObj);
                    if (player) {
                        return player.duration;
                    }
                },
                get progress() {
                    let player = that.getPlayerType(versionObj);
                    if (player && player.progress) {
                        return (player.progress * 100).toFixed(2);
                    }
                },
                get proofStatus() {
                    if (versionObj.proofData) {
                        return versionObj.proofData.status;
                    }
                },
                get buffered() {
                    let player = that.getPlayerType(versionObj);
                    if (player && player.buffered) {
                        return {
                            start: player.buffered.startInSec,
                            end: player.buffered.endInSec,
                        };
                    }
                },
                get time() {
                    let player = that.getPlayerType(versionObj);
                    return player ? player.time : 0;
                }
            },
            get isLoading() {
                let player = that.getPlayerType(versionObj);
                return player ? !player.isReady : true;
            },
            get isGifFile() {
                return versionObj.isGifFile;
            },
            get isScrubberVisible() {
                return versionObj.isScrubberVisible;
            },
        };
    }

    canUpdateComment = () => false;

    getPlayerType(versionObj) {
        if (versionObj.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left) {
            return this.leftVideoPlayer;
        } else if(versionObj.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right) {
            return this.rightVideoPlayer;
        }
    }

    isValidProof() {
        return !!this.leftImageData.proofId;
    }

    showDialog(type) {
        this.dialog = {
            type: type,
            location: 'modal',
        };
    }

    init() {
        this.$$.$scope.appCtrl.isShownLogo = false;
        this.$$.$scope.headerControls.show = false;
        this.loadVersionsData(this.leftProofId, this.leftImageData)
            .then(allowedVersions => {
                if (!this.rightProofId &&
                    allowedVersions && allowedVersions.length > 1) {
                    this.rightImageData.allowedVersions = JSON.parse(JSON.stringify(allowedVersions));
                    this.updateTypeOfVersions(this.rightImageData.allowedVersions, 'right');
                    this.setVersions(allowedVersions);
                } else if (!this.rightProofId) {
                    this.showDialog('two-versions-at-least');
                }
                if (this.rightProofId) {
                    this.setLeftRightVersion(allowedVersions, this.leftProofId, this.leftImageData);
                }
            });

        if (this.rightProofId) {
            this.loadVersionsData(this.rightProofId, this.rightImageData)
                .then(allowedVersions => {
                    this.isSameProof = allowedVersions && allowedVersions.some(version => version.proofId === this.leftProofId);
                    this.setLeftRightVersion(allowedVersions, this.rightProofId, this.rightImageData)
                });
        }

        this.beforeDestroy(() => {
            this.$$.$scope.appCtrl.isShownLogo = true;
            this.$$.$scope.headerControls.show = true;
        });
    }

    loadVersionsData(proofId, versionObj) {
       return this.loadVersions(proofId, versionObj)
            .then(allowedVersions => allowedVersions)
            .catch((err) => {
                console.debug("Error ", err);
            });
    }

    renderSmartCompareDiffOverlay(canvas) {
        let rootElement = canvas.__smartCompareDiffOverlayElement;

        const showSmartCompareDiffOverlay = this.showSmartCompareDiffOverlay && !this.imgSliderActive;

        if (!showSmartCompareDiffOverlay) {
            if (rootElement) {
                window.__pageproof_quark__.smartCompare.destroySmartCompareDiffOverlay(rootElement);
            }
            return;
        }

        if (!this.smartCompareDiffOverlayProps) {
            return;
        }

        if (!rootElement) {
            const overlayElement = canvas.getCustomOverlayElement();
            rootElement = document.createElement('div');
            overlayElement.appendChild(rootElement);
            canvas.__smartCompareDiffOverlayElement = rootElement;
        }

        window.__pageproof_quark__.smartCompare.renderSmartCompareDiffOverlay(
            rootElement,
            Object.assign({}, this.smartCompareDiffOverlayProps, {
                settings: this.smartCompareDiffSettings,
            })
        );
    }

    initWatchers() {
        this.beforeDestroy(this.$$.shortcutService.watch('proofInfo', () => {
            this.toggleProofInfo();
        }));

        this.beforeDestroy(this.$watch('showComments', (showComments) => {
            if ( ! showComments) {
                if (this.comments) {
                    this.comments.unselectComment();
                }
            }
        }));

        this.beforeDestroy(this.$watch('compareViewMode', (compareViewMode) => {
            if (compareViewMode === 'both') {
                this.setShowSmartCompareDiffOverlay(false);
            }
        }));

        this.beforeDestroy(this.$watch('centerCanvas', (centerCanvas) => {
            if (centerCanvas) {
                this.renderSmartCompareDiffOverlay(centerCanvas);
            }
        }));

        this.beforeDestroy(this.$watch('leftCanvas', (leftCanvas) => {
            if (leftCanvas) {
                this.renderSmartCompareDiffOverlay(leftCanvas);
            }
        }));

        this.beforeDestroy(this.$watch('rightCanvas', (rightCanvas) => {
            if (rightCanvas) {
                this.renderSmartCompareDiffOverlay(rightCanvas);
            }
        }));

        this.beforeDestroy(this.$watch('isCommenting', (isCommenting) => {
            // todo add left over comment
            // to not cancel addition of general comment whilst pen tool is still active
            if (isCommenting && this.interactionMode !== 'general') {
                this.cancelCreateComment();
            }
        }));

        this.$$.$scope.$watch(() => this.leftImageData.opacity, (opacity) => {
            if (opacity !== 0) {
                this.setActiveProofdata(this.leftImageData);
            }
        });

        this.$$.$scope.$watch(() => this.rightImageData.opacity, (opacity) => {
            if (opacity !== 0) {
                this.setActiveProofdata(this.rightImageData);
            }
        });

        this.$$.$scope.$watch(() => this.comparisonData.opacity, (opacity) => {
            if (opacity === 1) {
                this.setActiveProofdata();
            }
        });

        this.$$.$scope.$watch(() => this.comparisonData.mergeImages, (isMerged) => {
            this.updateOtherCanvasWithPin();
            setTimeout(() => {
                if (typeof isMerged !== 'undefined') {
                    this.resetCanvases();
                }
            }, 100);
        });

        // this.beforeDestroy(this.$$.scopeService.watchUntil(
        //   this.$$.$scope,
        //   () => this.leftVideoPlayer && this.leftVideoPlayer.on,
        //   () => {
        //           this.beforeDestroy(this.leftVideoPlayer.on('loadeddata', () => {
        //               this.leftVideoPlayer.updatePlayerWidthHeight(this.leftImageData.proofData.width, this.leftImageData.proofData.height);
        //               this.handleResizePreview(this.leftImageData);
        //           }));
        //         }
        // ));

        // this.beforeDestroy(this.$$.scopeService.watchUntil(
        //   this.$$.$scope,
        //   () => this.rightVideoPlayer && this.rightVideoPlayer.on,
        //   () => {
        //           this.beforeDestroy(this.rightVideoPlayer.on('loadeddata', () => {
        //               this.rightVideoPlayer.updatePlayerWidthHeight(this.rightImageData.proofData.width, this.rightImageData.proofData.height);
        //               this.handleResizePreview(this.rightImageData);
        //           }));
        //         }
        // ));
    }

    initKeyboardShortcuts() {
        this.beforeDestroy(this.$$.shortcutService.watch('commentTool', () => {
            if (this.canCreateComment() && !this.$$.domService.isFullScreen()) {
                this.isCommenting = ! this.isCommenting;
            }
        }));

        // Mark the selected comment status as done (default key binding: `)
        this.beforeDestroy(this.$$.shortcutService.watch('markComment', () => {
            this.changeCommentStatusByShortcutKey();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('commentPane', () => {
            this.toggleCommentPane();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomIn', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.nudgeZoomIn();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomOut', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.nudgeZoomOut();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomFit', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.fitCanvas();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom100', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.zoomToCanvas(100);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom200', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.zoomToCanvas(200);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom400', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.zoomToCanvas(400);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('rotate', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.rotateCanvas();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('rotateCC', () => {
            if (this.getMergeStatus() || this.isLinked) {
                this.rotateCanvas(true);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('ruler', () => {
            if (this.leftImageData.proofData.measurementUnits) {
                this.onChangeRulerMode(!this.isActivatedRuler);
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('marqueeZoom', () => {
            this.onChangeMarqueeZoomMode(!this.isActivatedMarqueeZoom);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('togglePins', () => {
            this.togglePins();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('outline', () => {
            this.toggleOutline();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('invertPins', () => {
            this.invertPins();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nextPage', () => {
            this.nextPage();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('previousPage', () => {
            this.prevPage();
        }));
    }

    changeCommentStatusByShortcutKey() {
        const selectedComment = this.comments.selectedComment;
        const proof = this.getLatestProofObj();
        if (!selectedComment || selectedComment.proofId !== proof.proofId) return;

        const nextCommentStatusType = this.$getNextCommentStatusType(proof.permissions, selectedComment);
        if (nextCommentStatusType) {
            this.markComment(selectedComment, nextCommentStatusType.type, nextCommentStatusType.state);
        }
    }

    onChangeRulerMode(selected) {
        this.interactionMode = selected ? 'ruler' : null;
        this.isCommenting = false;
        this.isActivatedRuler = selected;
        this.isActivatedMarqueeZoom = this.isActivatedMarqueeZoom && !selected;
        this.onInteractionModeChanged();
        if (!this.$$.$scope.$$phase) {
            this.$$.$scope.$apply();
        }
    }
    
    onChangeMarqueeZoomMode(selected) {
        this.interactionMode = selected ? 'marqueeZoom' : null;
        this.isCommenting = false;
        this.isActivatedMarqueeZoom = selected;
        this.isActivatedRuler = this.isActivatedRuler && !selected;
        this.onInteractionModeChanged();
        if (!this.$$.$scope.$$phase) {
            this.$$.$scope.$apply();
        }
    }

    toggleCommentPane() {
        this.showComments = ! this.showComments;
        if ( ! this.commentCount && ! this.leftImageData.isCreatingComment && ! this.rightImageData.isCreatingComment) {
            // Prevent the comment pane from showing using the keyboard shortcut
            this.showComments = false;
        }
    }

    togglePins(toggle = this.showPins) {
        this.showPins = !toggle;
        if (this.leftCanvas) this.leftCanvas.setEnablePins(this.showPins);
        if (this.rightCanvas) this.rightCanvas.setEnablePins(this.showPins);
    }

    toggleOutline() {
        this.outline = !this.outline;
        if (this.leftCanvas) this.leftCanvas.setKeyline(!this.leftCanvas.enableKeyline);
        if (this.rightCanvas) this.rightCanvas.setKeyline(!this.rightCanvas.enableKeyline);
    }

    invertPins() {
        this.isInvertedPins = !this.isInvertedPins;
        if (this.leftCanvas) this.leftCanvas.invertPins();
        if (this.rightCanvas) this.rightCanvas.invertPins();
    }

    setActiveProofdata(data) {
        this.storeCurrentActiveProofZoomDetails();
        this.activeProofData = (this.getMergeStatus() && data)
                                    ? data
                                    : (this.getMergeStatus() ? this.comparisonData : {});
        this.matchCanvasWithStoredZoomDetails();
    }

    matchBothCanvas(type, event) {
        if (this.shouldMatch() || this.imgSliderActive) {
            let {workingCanvas, tobeMatchedCanvas} = this.getCanvases(type);
            this.matchDbClickZoom(tobeMatchedCanvas, event);
            this.storeCurrentActiveProofZoomDetails(workingCanvas);
            this.matchCanvasWithStoredZoomDetails(tobeMatchedCanvas);

            if (this.imgSliderActive) {
                const syntheticEvent = new WheelEvent('syntheticWheel', {'deltaX': 0, 'deltaMode': 0});
                this.matchPanning(syntheticEvent, tobeMatchedCanvas);
            }
        }
    }

    matchDbClickZoom(canvas, event) {
        if (event && event.type === 'dblclick') {
            canvas._handleDoubleClickZoomEvent(event);
        }
    }

    matchPanning(event, type) {
        if (this.shouldMatch() || this.imgSliderActive) {
            let {workingCanvas, tobeMatchedCanvas} = this.getCanvases(type);
            this.setViewPort(tobeMatchedCanvas, workingCanvas.canvas.viewportTransform);
            tobeMatchedCanvas._handleScrollEvent(event);
        }
    }

    getCanvases(type) {
        let workingCanvas = (type === 'left') ? this.leftCanvas : this.rightCanvas,
            tobeMatchedCanvas = (type === 'left') ? this.rightCanvas : this.leftCanvas;
        return {workingCanvas, tobeMatchedCanvas};
    }

    isActiveCanvas(type) {
        return this.getMergeStatus() && (this.activeProofData.type === type);
    }

    loadVersions(proofId, versionObj) {
        return this.$$.proofRepositoryService.$getVersions(proofId)
            .then((versionData) => {
                if (typeof versionData === 'object' && versionData.length > 1) {
                    versionData.forEach ((version) => {
                        let name;
                        let text;
                        if (!version.Version) {
                            text = 'proof-comparison.dropdown.brief';
                        } else {
                            name = version.Version;
                            text = 'proof-comparison.dropdown.version';
                        }
                        if (version.CanAccess) {
                            versionObj.allowedVersions.push({
                                version: version.Version,
                                proofId: version.ProofId,
                                fileId: version.FileId,
                                pages: version.Pages,
                                title: version.Title,
                                type: versionObj.type,
                                name,
                                text,
                                value: version.Version
                            });
                        }
                    });
                    // todo come back here to see what to do with it
                    this.latestProofId =  versionData[versionData.length - 1].ProofId;
                    let allowedVersions = JSON.parse(JSON.stringify(versionObj.allowedVersions));
                    return allowedVersions;
                }
            });
    }

    setVersions(allowedVersions) {
        let versionData = {};
        allowedVersions.forEach(version => {
            //set left version data
            if (version.proofId === this.leftProofId && !this.leftImageData.proofId) {
                versionData = Object.assign(version, {
                    pageNumber: (this.leftPage) ? parseInt(this.leftPage) : this.getLeftProofPageNumber(version.pages),
                    type: 'left',
                    opacity: 1,
                })
                this.startGetImageUrl(this.leftImageData, versionData);
            }

            //set right version data
            if (this.rightProofId && version.proofId === this.rightProofId && !this.rightImageData.proofId) {
                versionData = Object.assign(version, {
                    pageNumber: (this.rightPage) ? parseInt(this.rightPage) : this.getLeftProofPageNumber(version.pages),
                    type: 'right',
                    opacity: 1,
                })
                this.startGetImageUrl(this.rightImageData, versionData);
            } else if (!this.rightProofId && !this.rightImageData.proofId && this.leftImageData.proofId) {
                const rightVersionData = this.getRightVersionData(allowedVersions);
                versionData = Object.assign(rightVersionData, {
                    pageNumber: this.getLeftProofPageNumber(rightVersionData.pages),
                    type: 'right',
                    opacity: 1,
                });
                this.startGetImageUrl(this.rightImageData, versionData);
            }
        });
    }

    getLeftProofPageNumber(proofPages) {
        const param2AsNumber = +this.$$.$routeParams.param2;
        if (isNaN(param2AsNumber)) {
            return 1;
        } else {
            if (proofPages < param2AsNumber) {
                return 1;
            }
            return param2AsNumber;
        }
    }

    setLeftRightVersion(allowedVersions, proofId, versionObj) {
        let foundVersion = allowedVersions && allowedVersions.find(version => version.proofId === proofId);
        versionObj.opacity = 1;
        if (!foundVersion) {
            foundVersion = {
                proofId: proofId,
            };
        }
        this.startGetImageUrl(versionObj, foundVersion);
    }

    hasAccessOnRightProof() {
        return !!(this.rightImageData.proofId);
    }

    isHtmlProof(proof) {
        return proof && proof.Data && proof.proofData.fileCategory === 'web';
    }

    getRightVersionData(allowedVersions) {
        let leftProofIndex = this.findIndexInData(allowedVersions, 'proofId', this.leftImageData.proofId);
        return (allowedVersions[leftProofIndex + 1]) ? allowedVersions[leftProofIndex + 1] : allowedVersions[leftProofIndex - 1];
    }

    findIndexInData(data, property, value) {
        var result = -1;
        data.some(function (item, i) {
            if (item[property] === value) {
                result = i;
                return true;
            }
        });
        return result;
    }

    setDimensions(versionObj) {
        versionObj.dimensions = {width: versionObj.proofData.width, height: versionObj.proofData.height};
    }

    updateToolProps(versionObj) {
        this.toolProps.switchPosition[versionObj.type] = versionObj.proofData;
    }

    cleanupKeyPairs() {
        const proofIds = Object.keys(this.keypairCleanupFunctions);
        proofIds.forEach((proofId) => {
            if (
                (proofId !== this.leftImageData.proofId && proofId !== this.rightImageData.proofId) ||
                this.$$destroyed
            ) {
                this.keypairCleanupFunctions[proofId]();
                delete this.keypairCleanupFunctions[proofId];
            }
        });
    }

    proofDidLoad({ proofData: proof }) {
        this.cleanupKeyPairs();
        if (proof.publicProofKeyPair) {
            if (this.keypairCleanupFunctions[proof.id]) {
                this.keypairCleanupFunctions[proof.id]();
            }
            this.keypairCleanupFunctions[proof.id] = this.$$.sdk.keyPairs.addKeyPair(proof.publicProofKeyPair);
        }
    }

    onError(err, proofId) {
        // Ignoring error restricted as it is coming from image load not data load
        if ( err === 'ERROR_RESTRICTED' ) {
            return; 
        } 
        this.proofLoadErrors[proofId] =  err;
        const isAdmin = err === 'ERROR_NO_ACCESS_ADMIN';
        if (Object.keys(this.proofLoadErrors).length === 2) {
            isAdmin ? this.showDialog('admin-no-access-both') : this.showDialog('no-access-both');
        } else {
            isAdmin ? this.showDialog('admin-no-access') : this.showDialog('no-access');
        } 
    }

    goToDashboard() {
        this.$$.$location.path('dashboard');
    }

    requestAccessToProof() {
        const proofIds = Object.keys(this.proofLoadErrors)
        const promises = proofIds.map( proofId => {
           return this.$$.sdk.proofs.requestAccess(proofId)
           
        }) 
        Promise.all(promises).then(() => {
            this.showDialog('request-access-success');
        })
    }

    addMeAsOwner() {
        const proofIds = Object.keys(this.proofLoadErrors)
        const promises = proofIds.map(proofId => {
            return(
                this.$$.backendService
                    .fetch('domain.dashboard.addOwner', {
                        email: this.user.email,
                        relatedId: proofId,
                        allVersions: false,
                    })
                    .data()
            )   
        }) 

        Promise.all(promises).then(() => {
            window.location.reload();
        })
    }

    startGetImageUrl(versionObj, newVersionData, hasBeenLinked) {
        return this.$$.$q((resolve) => {
            let proofDataPromise;
            let isPageChanged = !newVersionData;
            if (newVersionData){
                let tempPageNumber = versionObj.pageNumber;
                versionObj.pageNumber = (newVersionData.pages >= tempPageNumber && tempPageNumber !== 0) ? tempPageNumber : 1;
                versionObj.selectedVersion = newVersionData.version;
                versionObj = $.extend(versionObj, newVersionData);
                proofDataPromise =
                    versionObj.loadProofData()
                    .catch(err => this.onError(err, versionObj.proofId))
            }
            this.changeUrl();
            versionObj.loading = true;
            let wait = [proofDataPromise];
            this.$$.$q.all(wait).then(() => {
                this.proofDidLoad(versionObj);

                versionObj.loadProofPermissons();
                this.updateToolProps(versionObj);
                this.loadComments(versionObj);
                this.loadSpriteSheet(versionObj);
                this.setDimensions(versionObj);
                versionObj.updateFlags();

                if (this.isVideoOrAudio(versionObj)) {
                    this.isAllowedExtensions = false;
                    this.loadVideoAudioThumbnail(versionObj.proofData).then((dataUrl) => {
                        versionObj.url = dataUrl;
                        this.getImageDimensions(versionObj).then(() => {
                            this.handleResizePreview(versionObj);
                            this.updateVideoPlayerSource(versionObj);
                        });
                        this.finishGetImageUrl(versionObj).then(() => {
                            resolve();
                        });
                    });
                } else {
                    this.isAllowedExtensions = true;
                    this.getImageUrl(versionObj).then((dataUrl) => {
                        versionObj.url = dataUrl;
                        this.finishGetImageUrl(versionObj, isPageChanged, hasBeenLinked).then(() => {
                            resolve();
                        });
                    });
                }
            });

        });
    }

    loadComments(versionObj) {
        versionObj.commentsLoaded = false;
        versionObj.getCommentPinDataForPage()
            .then(() => this.updatePermissionsAndCount(versionObj))
            .then(() => versionObj.commentsLoaded = true)
            .then(() => {
                if (this.hasCommentsLoaded) {
                    this.setProofLoaded(true);
                }
            });
        if (versionObj.pages > 1) { // for page grid, if pages are more then 1, then only load all comments
            versionObj.loadAllComments();
        }
    }

    handleResizePreview (versionObj) {
        if (versionObj.imgWidth) {
            let previewBounds = this.getPreviewBounds();

            let dimensions = containBox({
                width: previewBounds.width,
                height: previewBounds.height,
            }, {
                width: versionObj.imgWidth,
                height: versionObj.imgHeight,
            });

            let player = this.getPlayerType(versionObj);

            if (!player.$$destroyed && player.control) {
                setTimeout(() => {
                    player.control.width(dimensions.width);
                    player.control.height(dimensions.height);
                    this.updateVideoPlayerPosition(null, versionObj);
                }, 1000);
            }
        }
    }

    getPageGridViewProps(type) {
        return {
          title: this[`${type}ImageData`].title,
          subtitle: 'Version ' + this[`${type}ImageData`].version,
          currentPage: this[`${type}ImageData`].pageNumber,
          pages: this[`${type}ImageData`].proofData.pages,
          onPageSelect: (selectedPage) => this.changePage(selectedPage.pageNumber, type),
          fileId: this[`${type}ImageData`].fileId,
          show: this[`${type}ImageData`].showGrid,
          showPageGridIcon: true,
          pagesMetadata: this[`${type}ImageData`].proofData.pagesMetadata,
        };
    }

    getPreviewBounds() {
        let {width: pageWidth, height: pageHeight} = document.documentElement.getBoundingClientRect();
        let width = Math.abs(pageWidth / 2 - (200));
        let height = Math.abs(pageHeight - 350);
        return {width, height};
    }

    finishGetImageUrl(versionObj, isPageChanged, hasBeenLinked) {
        return this.areSameSizes().then((isSameSize) => {
            return this.isSameSize = isSameSize;
        }).then(() => {
            if (isPageChanged) {
                if (versionObj.proofData.isLatestVersion && this.temporaryComment && this.interactionMode === 'draw') {
                    const latestVersionCanvas = this.getLatestVersionCanvas();
                    if (versionObj.pageNumber === this.temporaryComment.pageNumber) {
                        latestVersionCanvas.showDrawingElem();
                    } else {
                        latestVersionCanvas.hideDrawingElem();
                    }
                }
            } else {
                this.resetCanvases();
            }
            this.setScrubbers(versionObj);
            this.updateLinkStatus(isPageChanged, hasBeenLinked);

            return versionObj.loading = false;
        });
    }

    updateVersion(newVersionData) {
        this.resetComparisonData();
        if (newVersionData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left) {
            this.startGetImageUrl(this.leftImageData, newVersionData);
        } else if (newVersionData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right) {
            this.startGetImageUrl(this.rightImageData, newVersionData);
        }
    }

    resetComparisonData() {
        this.comparisonData = {};
        this.updateCompareMode(false);
        this.updateOpacity();
    }

    getImageUrl(data) {
        return this.$$.proofRepositoryService.$getValidThumbnailUrls(data.proofId, data.fileId)
            .then(file => {
                const thumbnail = file.images.pages.filter(p => (p.number === data.pageNumber))[0];
                const {version, envelope} = file.envelope;
                const qualityImage = thumbnail['high'];
                if (qualityImage === null) {
                    // image doesn't exist
                    return null;
                }
                return fetch(qualityImage.url)
                    .then(response => response.blob())
                    .then(data => this.$$.sdk.encryption.decrypt({ version, data, envelope }))
                    .then(data => URL.createObjectURL(data));
            });
    }

    loadSpriteSheet(data) {
        if (data.pages <= 1) {
            return;
        }
        this.$$.sdk.files.preview(data.fileId, 1, 'ss').then(function (blob) {
            let spriteSheetCols = 10;
            let url = window.URL.createObjectURL(blob);
            let image = new Image();
            image.onload = function() {
                let imageWidth = image.naturalWidth;
                let imageHeight = image.naturalHeight;
                let cellWidth = imageWidth / spriteSheetCols;
                let cellHeight = imageHeight / Math.ceil(data.pages / spriteSheetCols);

                for (let page = 1; page <= data.pages; page++) {
                    let position = getSpriteSheetPosition(page, spriteSheetCols);
                    let croppedImage = cropImage(image, position.x * cellWidth, position.y * cellHeight, cellWidth, cellHeight);
                    setTimeout(() => {
                        data.proofData.pages[page - 1].sprite = croppedImage;
                    }, 25 * Math.min(page, 100)); // Once we get above page 100, don't defer anymore.
                }
            };
            image.src = url;
        });
    }

    loadVideoAudioThumbnail(proof) {
        return this.$$.$q((resolve, reject) => {
            this.$$.sdk.files
                .thumbnail(proof.fileId)
                .then((preview) => {
                    let dataUrl = window.URL.createObjectURL(preview);
                    resolve(dataUrl);
                });
        });
    }

    updateCompareMode(mode) {
        this.compareMode = !!mode;
    }

    updateIsComparing(isComparing) {
        this.isComparing = !!isComparing;
    }

    getIsComparing () {
        return this.isComparing;
    }

    toggleImages(versionObj, toggle) {
        this.updateMergeStatus(toggle || !this.getMergeStatus());
        this.updateOpacity('', versionObj);
        this.setActiveProofdata(versionObj);

        // This fixes an issue with some proofs not being resized correctly after clicking 'Show both'
        this.$$.$timeout(() => { 
            this.updateDimensions();
        }, 1);
    }

    showDifferences() {
        this.setShowSmartCompareDiffOverlay(true)
    }

    onQuickFlick() {
        if (this.showSmartCompareDiffOverlay) {
            this.setShowSmartCompareDiffOverlay(false);
        }
    }

    setShowSmartCompareDiffOverlay(show = false) {
        this.showSmartCompareDiffOverlay = show;
        this.renderSmartCompareDiffOverlays();
    }

    updateMergeStatus(mergeStatus) {
        this.comparisonData.mergeImages = !!mergeStatus;
    }

    toggleGrid(type) {
        if (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left && !this.leftImageData.loading) {
            this.leftImageData.showGrid = !this.leftImageData.showGrid;
            this.$$.$timeout(() => this.scrollToPage(), 500);
        } else if (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right && !this.rightImageData.loading) {
            this.rightImageData.showGrid = !this.rightImageData.showGrid;
            this.$$.$timeout(() => this.scrollToPage(), 500);
        }
    }

    scrollToPage() {
        return this.$$.domService.scrollTo(`.js-page-grid-current-page`, 500, 0 - (window.innerHeight / 2) + 100);
    }

    isGridOpened() {
        return this.leftImageData.showGrid || this.rightImageData.showGrid;
    }

    getMergeStatus () {
        return this.comparisonData.mergeImages;
    }

    canCompare() {
        return this.isAllowedExtensions && this.isSameSize;
    }

    areVideos() {
        return this.isVideo(this.leftImageData) && this.isVideo(this.rightImageData);
    }

    canShowRightZoom() {
        return (!this.isLoading && !this.getMergeStatus() && (!this.isSameSize || !this.isLinked) && !this.isVideo(this.rightImageData));
    }

    shouldMatch() {
        return this.canCompare() && this.isLinked && !this.getMergeStatus() && !this.areVideos();
    }

    areSameSizes() {
        return this.$$.$q((resolve) => {
            if(this.leftImageData.url && this.rightImageData.url) {
                let wait = [
                    this.getImageDimensions(this.leftImageData),
                    this.getImageDimensions(this.rightImageData)
                ];
                this.$$.$q.all(wait).then(() => {
                    resolve(typeof this.leftImageData.imgWidth !== 'undefined'
                            && typeof this.rightImageData.imgWidth !== 'undefined'
                            && this.leftImageData.imgWidth === this.rightImageData.imgWidth
                            && this.leftImageData.imgHeight === this.rightImageData.imgHeight);
                });
            } else {
                resolve(false);
            }
        });
    }

    getImageDimensions(versionObj) {
        return this.$$.$q((resolve) => {
            calculateImageDimensions(versionObj.url, (err, dimensions) => {
                if (!err) {
                    versionObj.imgWidth = dimensions.width;
                    versionObj.imgHeight = dimensions.height;
                }
                resolve(true);
            });
        });
    }

    isVideoOrAudio(data) {
        return data.proofData && (data.proofData.isVideo || data.proofData.isAudio);
    }

    isAudio(data) {
        return data.proofData && data.proofData.isAudio;
    }

    isVideo(data) {
        return data && data.proofData && data.proofData.isVideo;
    }

    isStatic(data) {
        return data && data.proofData && data.proofData.isStatic;
    }

    isVideoByExtension(extension) {
        return extension && this.$$.UserService.isVideoFile(extension);
    }

    getDifference() {
        return (this.comparisonData.percentageStr === '0.00') ? '0%' : (this.comparisonData.percentageStr < 1) ? '1%' : Math.round(this.comparisonData.percentageStr) + '%';
    }

    getCompareMode() {
        return this.compareMode;
    }

    changePage(newPageNumber, type) {
        if (this.isGridOpened()) {
            this.toggleGrid(type);
        }
        this.updateCompareMode(false);
        this.setPageNumbers(newPageNumber, type, this.isLinked).then(() => {
            this.updateLinkedPageNumber(newPageNumber, type);
        });
    }

    getOtherAreaType(type) {
        return (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left)
            ? CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right
            : CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left;
    }

    updateLinkedPageNumber(pageNumber, type) {
        let areaType = this.getOtherAreaType(type);
        if (this.isLinked && this.isValidPageNumber(areaType, pageNumber)) {
            this.setPageNumbers(pageNumber, areaType, this.isLinked);
        }
    }

    isValidPageNumber(type, pageNumber) {
        switch (type) {
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left:
                return pageNumber > 0 && pageNumber <= this.leftImageData.pages;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right:
                return pageNumber > 0 && pageNumber <= this.rightImageData.pages;
        }
    }


    setPageNumbers(newPageNumber, type, hasBeenLinked){
        return this.$$.$q((resolve) => {
            if (newPageNumber && type) {
                if (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left && !this.leftImageData.loading && !this.isComparing && newPageNumber != this.leftImageData.pageNumber){
                    this.leftImageData.pageNumber = parseInt(newPageNumber);
                    this.startGetImageUrl(this.leftImageData, null, hasBeenLinked);
                } else if (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right && !this.rightImageData.loading && !this.isComparing && newPageNumber != this.rightImageData.pageNumber) {
                    this.rightImageData.pageNumber = parseInt(newPageNumber);
                    this.startGetImageUrl(this.rightImageData, null, hasBeenLinked);
                }
                resolve();
            }
        });
    }

    goToPage = (proofData) => {
        const returningProofId = proofData ? proofData.proofId : this.latestProofId;
        const latestProofDataSide = (this.leftImageData.proofId === returningProofId) 
        ? this.leftImageData 
        : this.rightImageData;

        let url = (!proofData && !this.isSameProof)
            ? '/dashboard'
            : ('/proof/static/' + returningProofId);
        
        this.$$.$location.path(url);    
        if (latestProofDataSide.pages > 1) {
            this.$$.$location.search('page', latestProofDataSide.pageNumber);
        } 
        this.$$.$location.replace();
        if (!this.$$.$scope.$$phase) this.$$.$scope.$apply();
    }

    changeUrl() {
        if (this.leftImageData.proofId && this.rightImageData.proofId) {
            let url = '/proof/compare/' + this.leftImageData.proofId + '/' + this.leftImageData.pageNumber + '/' + this.rightImageData.proofId + '/' + this.rightImageData.pageNumber;
            if (this.$$.$location.path() !== url) {
                this.$$.$location.path(url).ignore();
            }
        }
    }

    findDifferences() {
        this.$$.SegmentIo.track(46, {
            'proof id left': this.leftImageData.proofId,
            'proof id right': this.rightImageData.proofId,
        });
        this.updateIsComparing(true);
        this.renderSmartCompareDiffOverlays();
        return this.isComparisonExist().then((existDiffData) => {
            if (existDiffData) {
                this.comparisonData.url = existDiffData.url;
                return existDiffData;
            } else {
                return this.compareTwoImages(this.leftImageData.url, this.rightImageData.url).then((returnDiffData) => {
                    this.comparisonData.url = returnDiffData.imageUrl;
                    return returnDiffData;
                });
            }
        }).then((returnDiffData) => {
            this.smartCompareDiffOverlayProps = {
                imageUrl: this.comparisonData.url,
            };
            this.setShowSmartCompareDiffOverlay(true);
            if (returnDiffData.rawMisMatchPercentage >= SMART_COMPARE_THRESHOLD && returnDiffData.rawMisMatchPercentage < 1) {
                this.comparisonData.percentageStr = returnDiffData.rawMisMatchPercentage.toFixed(3);
            } else {
                this.comparisonData.percentageStr = returnDiffData.misMatchPercentage || returnDiffData.rawMisMatchPercentage.toFixed(3);
            }
            this.comparisonData.isSameDimensions = returnDiffData.isSameDimensions;
            this.comparisonData.type = CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center;
            this.storeComparisonResult();
            this.updateCompareMode(true);
            this.toggleImages(this.comparisonData, true);
            this.updateIsComparing(false);
            this.renderSmartCompareDiffOverlays();
        });
    }

    compareTwoImages(img1, img2) {
        resemble.outputSettings({
            errorColor: {
                red: 0,
                green: 0,
                blue: 0
            },
            transparency: 0,
            largeImageThreshold: 0,
        });
        return this.$$.$q((resolve, reject) => {
            const useResemble = new URLSearchParams(location.search).get('smart-compare') === 'legacy';
            if (useResemble) {
                resemble(img1).compareTo(img2).ignoreAntialiasing().onComplete(function(data){
                    console.debug("difference data in compare controller: ", data, img1, img2);
                    if (data) {
                        resolve({
                            isSameDimensions: data.isSameDimensions,
                            misMatchPercentage: data.misMatchPercentage,
                            rawMisMatchPercentage: data.rawMisMatchPercentage,
                            imageUrl: URL.createObjectURL(dataURLToBlob(data.getImageDataUrl())),
                        });
                    } else {
                        reject("Could Not run Resemble. There is something wrong.");
                    }
                });
            } else {
                window.__pageproof_quark__.smartCompare.diffImages(img1, img2).then((result) => {
                    const diffPercent = result.diffRatio * 100;
                    resolve({
                        isSameDimensions: true,
                        misMatchPercentage: diffPercent.toFixed(2),
                        rawMisMatchPercentage: diffPercent,
                        imageUrl: result.imageUrl,
                    });
                }, reject);
            }
        });
    }

    storeComparisonResult() {
        if (this.isNewComparisonData()) {
            this.comparisonStorage.push({
                                        'proof1': this.leftImageData.proofId,
                                        'pageFromProof1': this.leftImageData.pageNumber,
                                        'proof2': this.rightImageData.proofId,
                                        'pageFromProof2': this.rightImageData.pageNumber,
                                        'url': this.comparisonData.url,
                                        'isSameDimensions': this.comparisonData.isSameDimensions,
                                        'misMatchPercentage': this.comparisonData.percentageStr});
        }
        console.debug("comparison storage after :", this.comparisonStorage);
    }

    isNewComparisonData() {
        return !this.comparisonStorage.some((cachedData) => {
            return (cachedData.proof1 === this.leftImageData.proofId) && (cachedData.proof2 === this.rightImageData.proofId)
                && (cachedData.pageFromProof1 === this.leftImageData.pageNumber) && (cachedData.pageFromProof2 === this.rightImageData.pageNumber);
        });
    }

    isComparisonExist() {
        return this.$$.$q((resolve) => {
            let existData = false;
            this.comparisonStorage.some((cachedData) => {
                if ((cachedData.proof1 === this.leftImageData.proofId) && (cachedData.proof2 === this.rightImageData.proofId)
                    && (cachedData.pageFromProof1 === this.leftImageData.pageNumber) && (cachedData.pageFromProof2 === this.rightImageData.pageNumber)) {
                    existData = {'misMatchPercentage': cachedData.misMatchPercentage, 'isSameDimensions': cachedData.isSameDimensions, 'url': cachedData.url};
                    return true;
                }
            });
            resolve(existData);
        });
    }

    updateOpacity(opacityObj, versionObj) {
        if (opacityObj) {
            this.leftImageData.opacity = opacityObj.leftOpacity;
            this.comparisonData.opacity = opacityObj.diffOpacity;
            this.rightImageData.opacity = opacityObj.rightOpacity;
        } else if (!this.getMergeStatus()){
            this.leftImageData.opacity = this.rightImageData.opacity = 1;
            this.comparisonData.opacity = 0;
        } else if (versionObj) {
            switch(this.compareViewMode) {
                // comparisonData.opacity = 0 only set in 'diff' as value does not need to change between modes thereafter
                // & diff is always first mode selected when entering smart compare
                case 'diff':
                    this.comparisonData.opacity = 0;
                    this.rightImageData.opacity = 0;
                    this.leftImageData.opacity = 0;
                    versionObj.opacity = 1;
                    break;
                case 'left':
                    this.leftImageData.opacity = 1;
                    this.rightImageData.opacity = 0;
                    versionObj.opacity = 0;
                    break;
                case 'right':
                    this.leftImageData.opacity = 0;
                    this.rightImageData.opacity = 1;
                    versionObj.opacity = 0;
                    break;
                default: 
                    break;
            }
        } else {
            this.leftImageData.opacity = this.rightImageData.opacity = 0;
            this.comparisonData.opacity = 1;
        }
        this.updateInfoIconVisibility();
    }

    updateInfoIconVisibility() {
        this.canShowInfoIcon = this.leftImageData.opacity === 1 || this.rightImageData.opacity === 1;
    }

    addNewlyCreatedComment(comment) {
        this.getValidProofObjById(comment.proofId).addNewlyCreatedComment(comment);
    }

    getLatestProofObj() {
        if (this.rightImageData.proofId === this.latestProofId) {
            return this.rightImageData;
        } else if (this.leftImageData.proofId === this.latestProofId) {
            return this.leftImageData;
        } else {
            return null;
        }
    }

    getValidProofObjById(proofId) {
        if (this.rightImageData.proofId === proofId &&
            this.rightImageData.proofData.isLatestVersion) {
            return this.rightImageData;
        } else if (this.leftImageData.proofId === proofId &&
            this.leftImageData.proofData.isLatestVersion) {
            return this.leftImageData;
        } else {
            return null;
        }
    }

    /**
     * Delegates to $validateAttachmentType
     *
     * @param {File} file
     * @returns {Boolean}
     */
    validateAttachmentType (file) {
        return this.$validateAttachmentType(file);
    }

    /**
     * Delegates to $validateAttachmentSize.
     * @param {File} file
     * @returns {Boolean}
     */
    validateAttachmentSize (file) {
        if (!this.$validateAttachmentSize(file)) {
            this.showDialog(this.$$.PPProofDialogType.ATTACHMENT_SIZE);
            return false;
        }
        return true;
    }

    checkifCanCreateComment(versionObj) {
        return (
            versionObj.proofData &&
            versionObj.proofData.isLatestVersion &&
            versionObj.proofData.$permissions &&
            versionObj.proofData.$permissions.permissionObj.commentLevel.canCreate
        )
    }

    canCreateComment() {
        return (
            this.checkifCanCreateComment(this.leftImageData) ||
            this.checkifCanCreateComment(this.rightImageData)
        );
    }

    /**
     * When the user starts creating a comment on latest version of proof
     *
     * @param {Object || null} xy position data
     * @params {PPProof} latest proof object
     * @returns {PPProofComment}
     */
    startCreateComment(data, versionObj) {
        const latestVersionObj = this.getValidProofObjById(versionObj.proofId);

        if (this.isVideoOrAudio(versionObj) && 
            this.interactionMode === 'general' && 
            this.temporaryComment
        ) {
            this.toggleBothPlayers(true);
            return this.temporaryComment;
        }

        let comment = this.$startComment(latestVersionObj.proofData, this.user, null, data);
        comment.pageNumber = latestVersionObj.pageNumber = versionObj.pageNumber; // All comments for videos are on page #1

        if (this.isVideoOrAudio(versionObj)) {
            this.toggleBothPlayers(true);

            if (this.interactionMode !== 'general') {
                const pin = comment.pins[0];
                pin.time = this.getPlayerType(versionObj).currentTime;
                pin.duration = latestVersionObj.flags.commentDuration;
            } else {
                comment.metadata.mediaTime = {
                    time: this.getPlayerType(versionObj).currentTime,
                    duration: 0,
                };
            }
        }

        // Set the temporary pin data & comment data & show the `commentCreate` directive
        this.temporaryComment = comment;

        // Disable the pen tool & show the comments box unless using general pen tool
        // general pen tool should remain active whilst commenting
        if (this.interactionMode !== 'general') {
            this.isCommenting = false;
        }

        this.showCommentCreate = true;
        // To open comment pane
        this.showComments = true;
        // Show the temporary pin and create box in version object page
        versionObj.isCreatingComment = true;

        this.scrollAndFocusComment(versionObj);

        return comment;
    }

    scrollAndFocusComment(versionObj) {
        const createCommentId = `#create-comment-${versionObj.proofId}-${versionObj.pageNumber}`;
        this.$scrollAndFocusComment(createCommentId);
    }

    /**
     * When the user has chosen to create a comment.
     *
     * @param {PPProofComment} comment
     */
    finishCreateComment (comment) {
        const latestProofData = this.getValidProofObjById(comment.proofId);

        this.resetDrawing();
        comment.decryptedComment = comment.comment; // Immediately display the comment
        comment.createdAt = moment(); // Set the created at date (for the comment's view)
        // Reset the temporary comment data
        this.temporaryComment = null;

        // General comment on complete unselects as not previously unselected post markup like draw & pin
        if (this.interactionMode === 'general') {
            this.isCommenting = false;
        }

        // Hide the `commentCreate` directive
        this.leftImageData.isCreatingComment = this.rightImageData.isCreatingComment = false;
        this.showCommentCreate = false;

        // Send a request to create the new comment object
        this.createComment(comment)
            .then(() => {
                this.addNewlyCreatedComment(comment);
                this.updatePermissionsAndCount(latestProofData);
            });
    }

    createComment(comment) {
        comment.isTodo = this.isAutoTodo(comment);
        return this.$encryptAndCreateComment(this.encryptionData, comment)
            .then(() => {
                // If the comment has an attachment, upload it
                // This has to be done after the comment has been created (in the database) because
                // we need to assume the comment ID is available for the new function call...
                if (comment.attachments.length) {
                    this.$uploadAndAssignAttachments(comment, comment.attachments);
                }
            })
            .finally(() => {
                comment.decryptedComment = comment.comment;
                comment.$encryptedComment = comment.encryptedComment;
            });
    }

    replyComment(comment, reply) {
        const latestProofData = this.getVersionObjByProofId(comment.proofId);
        this.$replyComment(comment, reply, latestProofData.proofData)
            .then(() => this.updatePermissionsAndCount(latestProofData));
    }

    /**
     * Agree with a comment.
     *
     * @param {PPProofComment} comment
     */
    agreeComment(comment) {
        const versionObj = this.getVersionObjByProofId(comment.proofId);
        toggleValueInArray(comment.agrees, this.user.id);
        this.$agreeComment(comment)
            .then(() => this.updatePermissionsAndCount(versionObj));
        this.updatePermissionsAndCount(versionObj);
    }

    /**
     * Updates an existing comment.
     *
     * @param {PPProofComment} comment
     * @param {PPProofCommentAttachment} comment
     */
    updateComment (comment, newAttachments) {
        const latestProofData = this.getValidProofObjById(comment.proofId);
        this.$updateComment(comment, newAttachments, latestProofData.proofData)
            .then(() => this.updatePermissionsAndCount(latestProofData));
    }

    /**
     * Scrubs the video to a specific comment.
     * 'CORRECTION_TIME' added for correcting the currentTime bug - from AMP
     * There is a AMP bug of the setting of current-time.
     * It occurs when you want to set a specific time to current-time on the Video-player (AMP)
     * ex.) move to comment-time
     * If the specific time and duration are the same, current-time is moved to the point of the 90% of the duration.
     * For preventing this, corrected the specific time by 0.001 (second)
     *
     * @param {PPProofComment} comment
     */
    scrubToComment (comment, player) {
        if (comment.pin.length) {
            this.scrubToPin(comment.pins[0], player);
        }
    }

    scrubToPin(pin, player) {
        player.scrub(pin.time);
        player.scrubEnd();
        player.pause();
    }

    handleScrubToComment = (comment, versionObj) => {
        if (comment.pins.length) {
            this.handleScrubToPin(comment.pins[0], versionObj);
        }
    }

    handleScrubToPin(pin, versionObj) {
        const isTimeWithinDuration = ({time}, {durationTime}) => {
            return time <= durationTime;
        }

        if (this.isLinked &&
            isTimeWithinDuration(pin, this.leftVideoPlayer) &&
            isTimeWithinDuration(pin, this.rightVideoPlayer)) {
            this.scrubToPin(pin, this.leftVideoPlayer);
            this.scrubToPin(pin, this.rightVideoPlayer);
        } else {
            this.scrubToPin(pin, this.getPlayerType(versionObj));
        }
    }

    /**
     * Update comment with a new duration value
     *
     * @param {Number} commentIndex
     * @param {Number} newDuration
     */
    updateCommentDuration (versionObj, commentIndex, newDuration) {
        const comment = versionObj.comments[commentIndex];
        const maxDuration = this.getPlayerType(versionObj).duration - comment.time;
        comment.duration = newDuration > maxDuration ? maxDuration : newDuration;
        this.updateComment(comment);
    }

    isAutoTodo(comment) {
        const proof = this.getValidProofObjById(comment.proofId).proofData;
        return (proof.isOwnerOrCoOwner && proof.status > this.$$.PPProofStatus.PROOFING)
                || (proof.$permissions.proofStatus === this.$$.PPProofStatus.FINAL_APPROVING &&
                    ((proof.$permissions.isFinalApproverStage && proof.$permissions.isFinalApprover)
                    || proof.$permissions.permissionObj.proofLevel.isApprover));
    }

    updateTemporaryPin(data, versionObj, pinIndex) {
        const beforePin = this.temporaryComment.pins[pinIndex];
        const newPin = this.temporaryComment.pins[pinIndex] = this.canvasXYToCommentPin(data);

        if (beforePin && this.isVideoOrAudio(versionObj)) {
            newPin.time = beforePin.time;
            newPin.duration = beforePin.duration;
        }

        if (this.temporaryComment.pins[0].fill && versionObj) {
            this.scrollAndFocusComment(versionObj);
        }
    }

    hideCommentPane() {
        if (!this.canShowCommentPane()) {
            this.showComments = false;
        }
    }

    cancelCreateComment() {
        if (this.showCommentCreate) {
            this.temporaryComment = null;

            // Cancellation of new general comment should cease creation and become unselected
            // draw & pin have already been unselected
            if (this.interactionMode === 'general') {
                this.isCommenting = false;
            }

            this.leftImageData.isCreatingComment = this.rightImageData.isCreatingComment = false;
            this.showCommentCreate = false;
            this.hideCommentPane();
            this.resetDrawing();
        }
    }

    getSubTitle() {
        return (this.activeProofData.version && this.getMergeStatus() && this.activeProofData.opacity !== 0) ? 'Version ' + this.activeProofData.version : '';
    }

    toggleProofInfo () {
        if (this.getMergeStatus() && this.canShowInfoIcon) {
            this.toggleSelectedProofInfo(this.activeProofData.proofId || this.leftImageData.proofId);
        }
    }

    toggleSelectedProofInfo (proofId) {
        this.$$.proofInfoService.toggle(proofId, 'proof');
    }

    canShowArrows(type) {
        switch (type) {
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left:
                return this.leftImageData.pages > 1 && this.leftImageData.opacity == 1;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right:
                return this.rightImageData.pages > 1 && this.rightImageData.opacity == 1;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center:
                return this.leftImageData.pages > 1 && this.rightImageData.pages > 1 && this.comparisonData.opacity == 1;
        }
    }

    canShowPrevArrow(type) {
        switch (type) {
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left:
                return this.leftImageData.pageNumber > 1;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right:
                return this.rightImageData.pageNumber > 1;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center:
                return this.leftImageData.pageNumber > 1 && this.rightImageData.pageNumber > 1;
        }
    }

    canShowNextArrow(type) {
        switch (type) {
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left:
                return this.leftImageData.pages > this.leftImageData.pageNumber;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right:
                return this.rightImageData.pages > this.rightImageData.pageNumber;
            case CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center:
                return (this.leftImageData.pages > this.leftImageData.pageNumber) && (this.rightImageData.pages > this.rightImageData.pageNumber);
        }
    }

    changePagePrev() {
        this.leftImageData.pageNumber -= 1;
        this.rightImageData.pageNumber -= 1;
        this.getImageAndCompare()
    }

    changePageNext() {
        this.leftImageData.pageNumber += 1;
        this.rightImageData.pageNumber += 1;
        this.getImageAndCompare();
    }

    prevPage() {
        if (this.imgSliderActive || this.isLoading) {
            return
        }

        if (this.getMergeStatus()) {
            if (this.canShowPrevArrow('center')) {
                this.changePagePrev()
            }
        } else {
            if (this.canShowPrevArrow('left')) {
                this.changePage((this.leftImageData.pageNumber - 1), 'left');
            }
    
            if (this.canShowPrevArrow('right')) {
                this.changePage((this.rightImageData.pageNumber - 1), 'right');
            }
        }
    }

    nextPage() {
        if (this.imgSliderActive || this.isLoading) {
            return
        }

        if (this.getMergeStatus()) {
            if (this.canShowNextArrow('center')) {
                this.changePageNext()
            }
        } else {
            if (this.canShowNextArrow('left')) {
                this.changePage((this.leftImageData.pageNumber + 1), 'left');
            }
    
            if (this.canShowNextArrow('right')) {
                this.changePage((this.rightImageData.pageNumber + 1), 'right');
            }
        }
    }

    getImageAndCompare() {
        this.smartCompareDiffOverlayProps = {
            imageUrl: '',
        };
        this.renderSmartCompareDiffOverlays();
        let imageURLs = [
            this.startGetImageUrl(this.leftImageData),
            this.startGetImageUrl(this.rightImageData),
        ];
        this.$$.$q.all(imageURLs).then(() => {
            this.findDifferences();
        });
    }

    updateLinkStatus(isPageChanged, hasBeenLinked) {
        const canBeLinked = this.canCompare() || this.areVideos();

        // If the unlinked page is changed and the versions can be linked, we do not want to change isLinked
        if (!hasBeenLinked && isPageChanged && canBeLinked) {
            return;
        }

        this.isLinked = canBeLinked;
    }

    getActiveCanvas() {
        if (this.activeProofData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left) {
            return this.leftCanvas;
        } else if (this.activeProofData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right) {
            return this.rightCanvas;
        } else if (this.activeProofData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center) {
            return this.centerCanvas;
        }
    }

    _getZoomOptions(canvas) {
        const current = this.getTargetZoom(canvas);
        let previous = ZOOM_LEVELS[0];
        let next = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
        ZOOM_LEVELS.some(level => {
            if (level < current) {
                previous = level;
            }
            if (level > current) {
                next = level;
                return true;
            }
        });
        return {previous, next};
    }

    getZoom(canvas) {
        return (this.getActiveCanvas())
                    ? this.getActiveCanvas().getZoom()
                    : (canvas) ? canvas.getZoom() : '';
    }

    getTargetZoom(canvas) {
        let activeCanvas = this.getActiveCanvas() || canvas;
        return activeCanvas.getTargetZoom() * 100;
    }

    zoomOutCanvas(type) {
        let {workingCanvas} = this.getCanvases(type);
        this.zoomOut(workingCanvas);
        this.matchBothCanvas(type);
    }

    zoomOut(canvas) {
        let activeCanvas;

        if (this.imgSliderActive) {
            activeCanvas = canvas;
        } else {
            activeCanvas = this.getActiveCanvas(canvas) || canvas;
        }
        const {previous} = this._getZoomOptions(activeCanvas);
        activeCanvas._zoomTo(previous);
    }

    zoomInCanvas(type) {
        let {workingCanvas} = this.getCanvases(type);
        this.zoomIn(workingCanvas);
        this.matchBothCanvas(type);
    }

    zoomIn(canvas) {
        let activeCanvas;

        if (this.imgSliderActive) {
            activeCanvas = canvas;
        } else {
            activeCanvas = this.getActiveCanvas(canvas) || canvas;
        }
        
        const {next} = this._getZoomOptions(activeCanvas);
        activeCanvas._zoomTo(next);
    }

    nudgeZoomIn(type) {
        // TODO: clean out this if else and just switch to not use activeCanvas as first option. 
        // With imgslider we don't update activecanvas or activeproofdata which makes this not work. 
        // Will put this in as a hotfix right now
        let canvas;
        if (this.imgSliderActive) {
            canvas = this.getCanvases(type).workingCanvas;
        } else {
            canvas = this.activeProofData.type
            ? this.getActiveCanvas()
            : this.getCanvases(type).workingCanvas;
        }
        const current = canvas.getTargetZoom() * 100;
        canvas._zoomTo(Math.min(current + NUDGE_ZOOM_GAP, ZOOM_LEVELS[ZOOM_LEVELS.length - 1]));
        this.matchBothCanvas(type);
    }

    nudgeZoomOut(type) {
        let canvas;
        if (this.imgSliderActive) {
            canvas = this.getCanvases(type).workingCanvas;
        } else {
            canvas = this.activeProofData.type
            ? this.getActiveCanvas()
            : this.getCanvases(type).workingCanvas;
        }
        const current = canvas.getTargetZoom() * 100;
        canvas._zoomTo(Math.max(current - NUDGE_ZOOM_GAP, NUDGE_ZOOM_GAP));
        this.matchBothCanvas(type);
    }

    zoomToCanvas(level, type) {
        let {workingCanvas} = this.getCanvases(type);
        this.zoomTo(level, false, workingCanvas);
        this.matchBothCanvas(type);
    }

    zoomTo(level, animate = true, canvas = false) {
        if (this.imgSliderActive) {
            canvas._zoomTo(level, null, animate);
        } else {
            (this.getActiveCanvas())
            ? this.getActiveCanvas()._zoomTo(level, null, animate)
            : (canvas)
                ? canvas._zoomTo(level, null, animate)
                : '';
        }
    }

    fitCanvas() {
        if (this.activeProofData.type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center) {
            return this.fit(this.centerCanvas);
        }

        this.fit(this.leftCanvas);
        if (this.isLinked) {
            this.fit(this.rightCanvas);
        }
    }

    fit(canvas) {
        canvas.rotate(0);
        canvas._100OrFit(true, 500);
    }

    resyncCanvas(workingCanvas, tobeMatchedCanvas) {
        const zoom = workingCanvas.getTargetZoom() * 100;
        const rotation = workingCanvas.getTargetAngle();
        const position = workingCanvas.image ? workingCanvas.getPosition() : null;
        const viewPort = workingCanvas.canvas.viewportTransform;

        tobeMatchedCanvas._setViewportPoint(viewPort);
        tobeMatchedCanvas.setPosition(position);
        tobeMatchedCanvas.rotate(rotation)
        tobeMatchedCanvas._zoomTo(zoom, null, false);
    }

    matchMarqueeZoom(type) {
        const { workingCanvas, tobeMatchedCanvas } = this.getCanvases(type);
        this.resyncCanvas(workingCanvas, tobeMatchedCanvas);
    }

    prepareForFlip(type) {
        const tobeMatchedCanvas = type === 'left' ? this.leftCanvas : this.rightCanvas;
        this.resyncCanvas(this.getActiveCanvas(), tobeMatchedCanvas);
    }

    updateDimensions() {
        if (this.leftCanvas) this.leftCanvas.updateDimensions();
        if (this.rightCanvas) this.rightCanvas.updateDimensions();
    }

    resetCanvases(animate = true) {
        const canvasFitPadding = {width: 60, height: 100};
        let canvasArray = [this.leftCanvas, this.rightCanvas, this.centerCanvas];
        this.updateDimensions();
        canvasArray.forEach((canvas) => {
            if (canvas && typeof canvas !== 'undefined' && canvas.imageId) {
                canvas.rotate(0);
                canvas.setFitPadding(canvasFitPadding);
                canvas._100OrFit(animate, 500);
            }
        });
    }

    fitImage(versionObj) {
        if (versionObj.proofSwitched) {
            this.resetCanvases(false);
            versionObj.proofSwitched = false;
        }
    }

    storeCurrentActiveProofZoomDetails(givenCanvas = null) {
        let activeCanvas = (givenCanvas) ? givenCanvas : this.getActiveCanvas();
        if (activeCanvas && (typeof activeCanvas !== 'undefined')) {
            this.storedZoom = activeCanvas.getTargetZoom() * 100;
            this.storedRotation = activeCanvas.getTargetAngle();
            this.storedPosition = (activeCanvas.image) ? activeCanvas.getPosition() : null;
            this.storedViewPort = activeCanvas.canvas.viewportTransform;
        }
    }

    matchCanvasWithStoredZoomDetails(tobeMatchedCanvas) {
        let canvas = (tobeMatchedCanvas) ? tobeMatchedCanvas : this.getActiveCanvas();
        if (canvas && canvas.imageId && this.storedZoom && this.storedPosition) {           
            if (Math.round(this.storedZoom * 10000) !== Math.round(canvas.getTargetZoom() * 1000000)) {
                this.zoomTo(this.storedZoom, true, canvas);
            }
            this.rotate(this.storedRotation, true, canvas);
            this.setPosition(canvas, this.storedPosition);
            if (!tobeMatchedCanvas) {
                this.setViewPort(canvas, this.storedViewPort);
            }
        }
    }

    setPosition(canvas, position) {
        canvas.setPosition(position);
    }

    setViewPort(canvas, viewport) {
        canvas._setViewportPoint(viewport);
    }

    rotateCanvas(counter, type = 'right') {
        let {workingCanvas} = this.getCanvases(type);
        this.rotate(!!counter, false, workingCanvas);
        this.matchBothCanvas(type);
    }

    rotate(counter, noChanges = false, canvas = null) {
        let activeCanvas;

        if (this.imgSliderActive) {
            activeCanvas = canvas;
        } else {
            activeCanvas = this.getActiveCanvas(canvas) || canvas;
        }
        if (activeCanvas && activeCanvas.imageId) {
            activeCanvas.rotate((noChanges) ? counter : activeCanvas.getTargetAngle() + (counter ? -90 : 90));
        }
    }

    togglePanningAndZooming() {
        let canCompare = this.canCompare();
        this.togglePanning(canCompare);
        this.toggleZooming(canCompare);
    }

    togglePanning(bool) {
        if (this.leftCanvas) this.leftCanvas.setEnablePan(bool);
        if (this.rightCanvas) this.rightCanvas.setEnablePan(bool);
    }

    toggleZooming(bool) {
        if (this.leftCanvas) this.leftCanvas.setEnableZoom(bool);
        if (this.rightCanvas) this.rightCanvas.setEnableZoom(bool);
    }

    /**
     * Previews an attachment.
     *
     * @see {FilePreviewController}
     * @param {PPProofCommentAttachment} attachment
     * @param {PPProofComment} comment
     */
    previewAttachment (attachment, comment) {
        if (attachment.hasProcessed) {
            this.attachment = attachment;
        } else {
            comment.$message = (
                this.$$.PPCommentMessageType[
                    attachment.id ? 'ATTACHMENT_PROCESSING' : 'ATTACHMENT_UPLOADING'
                    ]
            );
        }
    }

    downloadAttachment(attachment) {
        return this.$downloadFile(attachment);
    }

    updateVideoPlayerSource(versionObj) {
        let player = this.getPlayerType(versionObj);
        player.loop = player.loop || versionObj.isGifFile;
        let wait = Promise.resolve();

        if (!versionObj.proofData.video) { // For unlisted reviewer, we won't have video credential on proof load
            wait = this.$$.proofRepositoryService.$getVideoByProofId(versionObj.proofData.id, versionObj.proofData);
        }
        wait.then(data => {
            if (player.url !== versionObj.proofData.video.url) {
                player.url = versionObj.proofData.video.url;
                player.bearer = versionObj.proofData.video.bearer;
                if (player.updateSource) {
                    player.updateSource();
                    this.handleResizePreview(versionObj);
                }
            }
        })
    }

    /**
     * Handles downloading a file.
     *
     * @param {PPProof} proof
     * @param {PPFile|PPProofCommentAttachment} file
     * @returns {$q<Blob>}
     */
    $downloadFile(file) {
        if (file.$file) {
            this.$$.downloadManager.downloadFile(file.$file, file.name);
            return this.$$.$q.when(file.$file);
        } else {
            const { promise } = this.$$.fileService.downloadFile(file.id, file.name);
            promise.then(blob => {
                file.$file = blob;
            });
            return promise;
        }
    }

    canShowCommentTodoDoneCounts() {
        return this.leftImageData.canShowCommentTodoDoneCounts() ||
            this.rightImageData.canShowCommentTodoDoneCounts();

    }

    canShowCommentHeading() {
        return this.hasCommentsLoaded &&
            this.leftImageData.permissions &&
            this.rightImageData.permissions &&
            (
                this.filter.name ||
                this.canShowCommentTodoDoneCounts()
            );
    }

    updatePermissionsAndCount(versionObj) {
        versionObj.calculateCommentCounts()
            .then(() => this.populateMentionedUsers(versionObj.proofData, versionObj.pageNumber))
            .then(() => this.populateMentionData(versionObj.proofData, this.user, versionObj.pageNumber))
            .then(() => versionObj.loadProofPermissons())
            .then(() => this.calculateCountsForCommentHeader());
    }

    calculateCountsForCommentHeader() {
        const leftProofPage = this.leftImageData.proofData.pages
            ? this.leftImageData.proofData.pages[this.leftImageData.pageNumber - 1]
            : this.leftImageData.proofData;
        const rightProofPage = this.rightImageData.proofData.pages
            ? this.rightImageData.proofData.pages[this.rightImageData.pageNumber - 1]
            : this.rightImageData.proofData;
        let leftPageApproved = 0;
        let leftPageHighlight = 0;
        let rightPageApproved = 0;
        let rightPageHighlight = 0;
        if (leftProofPage && leftProofPage.labelCount) {
            ({ approved: leftPageApproved, highlight: leftPageHighlight } = leftProofPage.labelCount);
        }
        if (rightProofPage && rightProofPage.labelCount) {
            ({ approved: rightPageApproved, highlight: rightPageHighlight } = rightProofPage.labelCount);
        }
        this.commentCountsForHeader = {
            unmarkedCount: (leftProofPage.unmarkedCount + rightProofPage.unmarkedCount),
            todoCount: (leftProofPage.todoCount + rightProofPage.todoCount),
            doneCount: (leftProofPage.doneCount + rightProofPage.doneCount),
            agreeCount: (leftProofPage.agreeCount + rightProofPage.agreeCount),
            notAgreeCount: (leftProofPage.notAgreeCount + rightProofPage.notAgreeCount),
            attachmentCount: (leftProofPage.attachmentCount + rightProofPage.attachmentCount),
            repliesCount: (leftProofPage.repliesCount + rightProofPage.repliesCount),
            privateRepliesCount: (leftProofPage.privateRepliesCount + rightProofPage.privateRepliesCount),
            privateCount: (leftProofPage.privateCount + rightProofPage.privateCount),
            labelCount: {
                approved: (leftPageApproved + rightPageApproved),
                highlight: (leftPageHighlight + rightPageHighlight),
            },
            mentionedUsers: generalfunctions_getUniqueArrayItems([...this.leftImageData.proofData.mentionedUsers, ...this.rightImageData.proofData.mentionedUsers], 'id'),
            commentByUsers: generalfunctions_getUniqueArrayItems([...this.leftImageData.proofData.commentByUsers, ...this.rightImageData.proofData.commentByUsers], 'id'),
            // commentedPages: (leftProofPage.commentedPages + rightProofPage.commentedPages),
        };
    }

    canShowCommentPane() {
        return this.commentCount > 0 || this.showCommentCreate;
    }

    selectComment(comment, scroll = false, versionType, panToPins = false) {
        const versionObj = this.getVersionObjByProofId(comment.proofId);
        this.comments.selectComment(comment);

        if (scroll) {
            this.comments.scrollToComment(comment.id);
        }
        if (['left', 'right'].includes(versionType) &&
            this.getMergeStatus()) {
            this.updateOtherCanvasWithPin(comment)
        }

        if (panToPins) {
            if (versionObj.proofData.isStatic) {
                this.handlePanToPins(comment, versionType);
            } else {
                if (this.isGeneralComment(comment)) {
                    this.flashComment(comment, versionType);
                }
            }
        }
    }

    handlePanToPins(comment, type) {
        const { workingCanvas, tobeMatchedCanvas } = this.getCanvases(type);
        const pins = workingCanvas.getPinsByIdPrefix(comment.id);

        if (this.isGeneralComment(comment)) {
            workingCanvas.fit();
            this.flashComment(comment, type);

            if (this.isLinked) {
                tobeMatchedCanvas.fit();
            }
        } else {
            workingCanvas.panToPins(pins);
            if (this.isLinked) {
                tobeMatchedCanvas.panToPins(pins);
            }
        }
    }

    updateOtherCanvasWithPin(comment) {
        this.comparisonData.comments = comment ? [comment] : null;
        this.comparisonData.proofData = comment ? this.getVersionObjByProofId(comment.proofId) : null;
        this.comparisonData.generalCommentCount = !!comment && this.isGeneralComment(comment) ? 1 : 0;

        if (this.comparisonData.generalCommentCount) {
            const { TODO, DONE, UNMARKED } = this.$$.PPCommentMarkType;
            const commentStatusMark = this.$getCommentStatusMark(comment);

            this.comparisonData.generalCommentTodoCount = commentStatusMark === TODO ? 1 : 0;
            this.comparisonData.generalCommentDoneCount = commentStatusMark === DONE ? 1 : 0;
            this.comparisonData.generalCommentUnmarkedCount = commentStatusMark === UNMARKED ? 1 : 0;

            this.flashComment(comment, this.comparisonData.type);
        }
    }

    selectCommentByNumber(number, versionProof) {
        this.selectComment(this.comments.getCommentByNumber(number, versionProof), true);
    }

    getCommentDataByNumber(number, versionProof) {
        return this.comments.getCommentByNumber(number, versionProof);
    }

    /**
     * When the selection of a comment updates.
     *
     * @param {PPProofComment} previous
     * @param {PPProofComment} current
     */
    selectionUpdate(previous, current) {
        this.$selectionUpdate(previous, current);
        const versionObj = current && this.getVersionObjByProofId(current.proofId);
        if (current && this.isVideoOrAudio(versionObj)) {
            this.handleScrubToComment(current, versionObj);
        }
    }

    getVersionObjByProofId(proofId) {
        let versionObj = null;
        if (this.leftImageData.proofId === proofId) {
            versionObj = this.leftImageData;
        } else if (this.rightImageData.proofId === proofId) {
            versionObj = this.rightImageData;
        }
        return versionObj;
    }

    flashComment(comment, type) {
        if (this.isGeneralComment(comment)) {
            this.flashingGeneralComment[type] = null;
            this.$$.$timeout(() => this.flashingGeneralComment[type] = comment);
        } else {
            const { workingCanvas } = this.getCanvases(type);
            if (workingCanvas) {
                workingCanvas.flashPinsByIdPrefix(comment.id);
            }
        }
    }

    canReply = (proofId) => {
        const latestProofObj = this.getValidProofObjById(proofId);
        if (latestProofObj) {
            return latestProofObj.canReply();
        }
        return false;
    }

    /**
     * Delete a comment from the proof.
     *
     * @param {PPProofComment} comment
     */
    deleteComment(comment) {
        const versionObj = this.getVersionObjByProofId(comment.proofId);
        this.$$.SegmentIo.track(35, {
            'proof id': versionObj.proofId,
            'comment id': comment.id,
        }); // Delete Comment

        // Sends a request to the server to delete
        this.$deleteComment(comment)
            .then(() => {
                this.$removeComment(comment, versionObj);
                this.updatePermissionsAndCount(versionObj);
                this.hideCommentPane();
            });
    }

    /**
     * Delete an attachment from a comment.
     *
     * @param {PPProofCommentAttachment, PPProofCOmment} attachment, comment
     */
    deleteAttachment (attachment, comment) {
        this.$deleteAttachment(attachment, comment);
        comment.attachments = comment.attachments.filter(attachmentValue => attachmentValue !== attachment);
        comment.$message = null;
        const versionObj = this.getVersionObjByProofId(comment.proofId);
        this.updatePermissionsAndCount(versionObj);
    }

     /**
     * Mark/unmark a comment.
     *
     * @param {PPProofComment} comment
     * @param {PPCommentMarkType} type
     * @param {Boolean} state
     */
    markComment(comment, type, state) {
        const latestProofObj = this.getValidProofObjById(comment.proofId);
        const update = () => {
            this.updatePermissionsAndCount(latestProofObj);
        };
        this.$markComment(comment, type, state)
            .then(() => update());
        update();
    }

     /**
     * @param {PPProof} proof
     * @param {String} type
     * @param {Boolean} state
     * @returns {$q}
     */
    markAllComments(type, state) {
        const latestProofObj = this.getLatestProofObj();
        const update = () => {
            const leftFilteredComments = this.leftImageData.comments.filter(this.filter.fn);
            const rightFilteredComments = this.rightImageData.comments.filter(this.filter.fn);
            if (this.filter.name && leftFilteredComments.length === 0 && rightFilteredComments.length === 0) {
                this.$setFilter(null, latestProofObj.proofData);
            }
            this.updatePermissionsAndCount(latestProofObj);
        };
        this.$markAllComments(latestProofObj.proofData, type, state, latestProofObj.pageNumber).then(update);
        update();
    }

    getfilteredComments() {
        const latestProofObj = this.getLatestProofObj();
        return latestProofObj
            ? this.$getfilteredComments(latestProofObj.proofData, latestProofObj.pageNumber)
            : 0;
    }

    /**
     * Load the users permissions on the proof & the action/button.
     *
     * @see {BaseProofController.$loadPermissionsActionButton}
     */
    loadPermissionsActionButton() {
        const latestProofObj = this.getLatestProofObj();
        this.$loadPermissionsActionButton(latestProofObj.proofData, this.user)
            .then(({ $permissions, $actions, permissions, action, button }) => {
                latestProofObj.$permissions = $permissions;
                latestProofObj.$actions = $actions;
                latestProofObj.permissions = permissions;
                latestProofObj.action = action;
                latestProofObj.button = button;
            });
    }

    updateVideoPlayerPosition(position, versionObj) {
        const videoPlayer = this.getPlayerType(versionObj);

        this.updateCurrentCanvasPosition({
            top: position.y + 100,
            left: position.x,
            width: position.width,
            height: position.height,
            zoomLevel: videoPlayer.getZoom(),
            angle: 0,
            isRotating: false,
        }, versionObj);
    }

    whenVideoPanned(sourcePlayer, targetPlayer) {
        if (this.isLinked && sourcePlayer && targetPlayer) {
            targetPlayer.setPosition(sourcePlayer);
        }
    }

    updatePinById(proof, commentId, pinIndex, updatedPin) {
        const comment = proof.pages[0].comments.find(comment => comment.id === commentId);
        const pin = comment.pins[pinIndex];
        if (pin.time !== updatedPin.time || pin.duration !== updatedPin.duration) {
            pin.time = updatedPin.time;
            pin.duration = updatedPin.duration;
            this.updateComment(comment);
        }
    }

    selectCommentById(proof, commentId, scroll) {
        if (commentId === null) {
            this.comments.unselectComment();
        } else {
            const comment = proof.pages[0].comments.find(comment => comment.id === commentId);
            this.comments.selectComment(comment);
        }
    }

    updateCanvasPosition(data, versionObj) {
        if (this.isLinked && this.selectedCanvasType === versionObj.type) {
            this.debouncedMatchBothCanvas(versionObj.type);
        }

        this.updateCurrentCanvasPosition(data, versionObj);
    }

    getLeftPinColor = (comment) => {
        return this.getPinColor(comment, this.leftImageData.proofData);
    }

    getRightPinColor = (comment) => {
        return this.getPinColor(comment, this.rightImageData.proofData);
    }

    initCurrentCanvasPosition() {
        const defaultCurrentCanvasPosition = {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            zoomLevel: 1,
            angle: 0,
            isRotating: false,
            canvasContainerWidth: 0,
        };

        this.currentCanvasPosition = {
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left]: Object.assign({}, defaultCurrentCanvasPosition),
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.right]: Object.assign({}, defaultCurrentCanvasPosition),
            [CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center]: Object.assign({}, defaultCurrentCanvasPosition),
        };
    }

    getCurrentPageByType(type) {
        if (type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.center) {
            return this.comparisonData; 
        }

        const imageData = type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left
            ? this.leftImageData
            : this.rightImageData;
        return (imageData.proofData && imageData.proofData.pages)
            ? imageData.proofData.pages.find(page => page.pageNumber === imageData.pageNumber)
            : null;
    }

    generalCommentIconButtonsProps(type) {
        const that = this;
        const { proofData } = type === CompareProofController.COMPARE_SCREEN_AREA_OPTIONS.left
            ? that.leftImageData
            : that.rightImageData;
        return {
            get page() {
                return that.getCurrentPageByType(type);
            },
            get position() {
                return that.getGeneralCommentIconButtonsLocation(type);
            },
            get flashingType() {
                return that.flashingGeneralComment[type] ? that.getCommentMarkStatus(that.flashingGeneralComment[type], proofData) : null;
            },
            get filterName() {
                return that.filter.name;
            },
            get commentOrder() {
                return that.commentOrder;
            },
            compareType: type,
            targetZIndex: 1, // px-canvas's z-index
            getGeneralCommentsByType(comments) {
                const { TODO, DONE, UNMARKED } = that.$$.PPCommentMarkType;
                const markTypeComments = { [UNMARKED]: [], [TODO]: [], [DONE]: [] };

                const filtered = comments.filter(comment => that.filter.fn(comment) && that.isGeneralComment(comment));

                that.$$
                    .$filter('orderBy')(filtered, that.commentOrder.orderCommentsBy, that.commentOrder.isReversedCommentOrder)
                    .forEach((comment) => {
                        const markType = that.getCommentMarkStatus(comment, proofData);
                        markTypeComments[markType].push(comment);
                    });

                return markTypeComments;
            },
            selectGeneralComment(comment) {
                that.selectComment(comment, true);
            },
        };
    }

    getGeneralCommentIconButtonsLocation(type) {
        const { top, left, width, canvasContainerWidth } = this.currentCanvasPosition[type];

        return {
            top: top - HEADER_HEIGHT,
            left: (left + width > canvasContainerWidth) ? canvasContainerWidth : left + width,
        }
    }

    setSelectedCanvas(type) {
        this.selectedCanvasType = type;
    }

    getImgSliderStatus() {
        return this.imgSliderActive;
    };

    setOpacityImgSliderCanvases() {
        this.isLinked = true;
        this.comparisonData.opacity = 0;
        this.leftImageData.opacity = 1;
        this.rightImageData.opacity = 1;

        this.updateDimensions();
    };

    setImageSliderElements(latestCanvas) {
        if (latestCanvas.imageId.includes('left')) {
            this.sliderLeftImg = this.parentContainerElement.LEFT_CONTAINER[0];
            this.sliderRightImg = this.parentContainerElement.RIGHT_CONTAINER[0];
        } else {
            this.sliderRightImg = this.parentContainerElement.LEFT_CONTAINER[0];
            this.sliderLeftImg = this.parentContainerElement.RIGHT_CONTAINER[0];
        }
     
        this.imgSlider = this.imgComparisonSliderElement[0];
    };

    createImageSlider() {
        const that = this;
        
        if (this.imgSliderActive) {
            cleanupImageSlider();
            return;
        };

        const latestCanvas = this.getLatestVersionCanvas();
        if (!latestCanvas) {
            return;
        };

        this.setImageSliderElements(latestCanvas);
        this.imgSliderActive = true;
        this.renderSmartCompareDiffOverlays();
        this.setOpacityImgSliderCanvases();

        this.imgSlider.addEventListener('mousedown', slideReady)
        this.imgSlider.addEventListener('mouseup', slideFinish)
        this.imgSlider.addEventListener('touchstart', slideReady);
        window.addEventListener('mousemove', slideMove);
        window.addEventListener('touchend', slideFinish);

        let clicked = 0;
        let pos = 0;
        this.sliderLeftImg.style.clipPath = `inset(-100px 50% 0 0)`;
        this.sliderRightImg.style.clipPath = `inset(-100px 0 0 50%)`;
        this.imgSlider.style.left = latestCanvas.image.canvas.width / 2 + 'px';

        function slideReady(e) {
            clicked = 1;
            e.preventDefault();
        };
        function slideFinish() {
            clicked = 0;
        };

        function getCursorPos(e) {
            e = e.changedTouches ? e.changedTouches[0] : e;

            const xCord = e.clientX;
            const xPercent = xCord/window.innerWidth;
            return 100 * xPercent;
        };

        function slide(x) {
            that.sliderLeftImg.style.clipPath = `inset(-100px ${100-x}% 0 0)`;
            that.sliderRightImg.style.clipPath = `inset(-100px 0 0 ${x}%)`;
            that.imgSlider.style.left = x + '%';
        };

        function slideMove(e) {
            if (clicked == 0) return false;
            pos = getCursorPos(e)
            slide(pos);
        };

        function cleanupImageSlider() {
            that.sliderLeftImg.style.clipPath = null;
            that.sliderRightImg.style.clipPath = null;
            that.imgSliderActive = false;
            that.renderSmartCompareDiffOverlays();
            that.imgSlider.removeEventListener('mousedown', slideReady)
            that.imgSlider.removeEventListener('mouseup', slideFinish)
            window.removeEventListener('mousemove', slideMove);
            that.imgSlider.removeEventListener('touchstart', slideReady);
            window.removeEventListener('touchend', slideFinish);
        };
    };
}

app
    .controller('CompareProofController', CompareProofController);

/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import Popover from '../Popover';
import { Option, OptionList, Separator } from '../PopupMenu';
import OptionChanger from '../OptionChanger';
import Translation from '../Text/Translation';
import ShyTextPill from '../ShyTextPill';
import Input from '../Input';
import SubOptionList from './SubOptionList';
import { sdk } from '../../util/sdk';
import css from './ProofPageInfo.scss';
import UNITS from './constantUnits';
import EmailMetadata from './EmailMetadata';
import LinkCheckResultsContainer from '../../containers/LinkCheckResults';
import LinkSummaryDot from './LinkCheckResults/LinkSummaryDot';
import isBrowserTimezoneInCountry from '../../util/browser-timezone-by-country';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';
import ContentCredentials from '../ContentCredentials';
import CRIcon from '../ContentCredentials/CRIcon';

const DEFAULT_MEASUREMENT_UNIT_STORAGE_KEY = 'pageproof.app.default-measurement-unit.';
const THE_LIMIT_COUNT_FOR_DISPLAY = 5;
const INVALID_SCALE_INPUT_CHARS = ['-', '+', 'e', 'E'];

function setCachedMeasurementUnit(userId, unit) {
  window.localStorage.setItem(DEFAULT_MEASUREMENT_UNIT_STORAGE_KEY + userId, unit);
}

function getCachedMeasurementUnit(userId) {
  return window.localStorage.getItem(DEFAULT_MEASUREMENT_UNIT_STORAGE_KEY + userId);
}

function generateDimensionLabel({ unit, label, width, height }) {
  return UNITS[unit].isDecimal
    ? `${width.toFixed(1)} ${label || unit} x ${height.toFixed(1)} ${label || unit}`
    : `${width} ${unit} x ${height} ${unit}`;
}

function unitConvertor(originUnit, newUnit, width, height) {
  let convertedWidth = width;
  let convertedHeight = height;
  const { label } = UNITS[newUnit];

  if (originUnit === newUnit) {
    return { unit: newUnit, width, height, label };
  }
  if (originUnit !== 'mm') {
    if (UNITS[originUnit].formula === 'multiply') {
      convertedWidth /= UNITS[originUnit].formulaNumber;
      convertedHeight /= UNITS[originUnit].formulaNumber;
    } else {
      convertedWidth *= UNITS[originUnit].formulaNumber;
      convertedHeight *= UNITS[originUnit].formulaNumber;
    }
  }

  if (UNITS[newUnit].formula === 'multiply') {
    convertedWidth *= UNITS[newUnit].formulaNumber;
    convertedHeight *= UNITS[newUnit].formulaNumber;
  } else {
    convertedWidth /= UNITS[newUnit].formulaNumber;
    convertedHeight /= UNITS[newUnit].formulaNumber;
  }

  return { unit: newUnit, width: convertedWidth, height: convertedHeight, label };
}

function durationFormatter(duration) {
  const dateObject = new Date(null);
  dateObject.setSeconds(duration);
  return dateObject.toISOString().substr(11, 8);
}

function getColorNames(colorList) {
  const colorModelFilter = (color, colorModel) => colorModel(color.toLowerCase());
  const rgbFilter = color => color === 'red' || color === 'green' || color === 'blue';
  const cymkFilter = color => color === 'cyan' || color === 'magenta' || color === 'yellow' || color === 'black';
  let rgbCount = 0;
  let cmykCount = 0;
  let colorNames = [...colorList];

  colorList.forEach((color) => {
    rgbCount = colorModelFilter(color, rgbFilter) ? rgbCount + 1 : rgbCount;
    cmykCount = colorModelFilter(color, cymkFilter) ? cmykCount + 1 : cmykCount;
  });

  if (rgbCount === 3) {
    colorNames = ['RGB', ...colorList.filter(color => !colorModelFilter(color, rgbFilter))];
  } else if (cmykCount === 4) {
    colorNames = ['CMYK', ...colorList.filter(color => !colorModelFilter(color, cymkFilter))];
  }

  return colorNames;
}

function ProofPageInfo({
  isFooterMouseover,
  proof,
  duration,
  currentPage,
  tools,
  isActivatedRuler,
  isActivatedGridLines,
  onChangeRulerMode,
  onChangeGridLinesMode,
  updatePageDimensionInfo,
  originalWidth,
  originalHeight,
  measurementUnits,
  pageDimensionInfo,
}) {
  const {
    colorList,
    pageCount,
    fontList,
    pageDimensions,
    source,
    sourceMetadata,
    crData,
    audioMetaData,
    colorProfile,
    linkedFiles,
    fileType,
    file: { extension },
  } = proof;

  const { minimumScale, maximumScale } = pageDimensionInfo || {};

  const [initMeasurementUnit, setInitMeasurementUnit] = useState(measurementUnits);
  const [keepShowing, setKeepShowing] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(measurementUnits);
  const [dimensionLabelText, setDimensionLabelText] = useState('');
  const [scale, setScale] = useState(pageDimensionInfo && pageDimensionInfo.scale);
  const [isCRPopoverOpen, setIsCRPopoverOpen] = useState(false);

  const fontFamilyWithStyles = fontList
    .sort((a, b) => a.localeCompare(b))
    .reduce((a, v) => {
      const [fontName, style] = v.split(/-|,/);
      const fontStyle = style ? [style] : [];
      const fontStyles = a[fontName]
        ? [...a[fontName], ...fontStyle]
        : fontStyle;
      return {
        ...a,
        [fontName]: fontStyles,
      };
    }, {});

  const fontFamilyList = Object.keys(fontFamilyWithStyles);
  const colorNames = getColorNames(colorList);
  const pagesSizeData = pageDimensions
    .reduce((a, v) => ({
      ...a,
      [v.PageNumber]: {
        width: v.PageWidth,
        height: v.PageHeight,
      },
    }), {});
  const getConvertedDimensionData = unit => unitConvertor(
    measurementUnits,
    unit,
    pagesSizeData[currentPage] ? pagesSizeData[currentPage].width : originalWidth,
    pagesSizeData[currentPage] ? pagesSizeData[currentPage].height : originalHeight,
  );
  const handleClickRuler = (popover) => {
    if (!isActivatedRuler) {
      popover.hide();
      setKeepShowing(false);
    }
    onChangeRulerMode(!isActivatedRuler);
  };
  const handleClickGridLines = (popover) => {
    if (!isActivatedGridLines) {
      popover.hide();
      setKeepShowing(false);
    }
    onChangeGridLinesMode(!isActivatedGridLines);
  };

  const handleChangeScale = (value) => {
    // The user can enter 0 or 0.0 or 0.00 for entering any decimal less than 1
    // For ensuring the decimal, checks the value using 'Number'.
    if (Number(value) === 0 || (value >= minimumScale && value <= maximumScale)) {
      setScale(value);
    }
  };

  const onSubmitScale = () => {
    if (scale === '' || Number(scale) === 0) {
      setScale(pageDimensionInfo.scale);
    } else {
      updatePageDimensionInfo({ scale: Number(scale) });
    }
  };

  const emailMetadata = source === 'email' && sourceMetadata ? JSON.parse(sourceMetadata) : undefined;

  const [linkCheckResults, setLinkCheckResults] = useState(null);
  const hasLinkResults = linkCheckResults && linkCheckResults.results.reduce((total, current) => total + current.summary.total, 0) > 0;
  const showLinkChecker = proof.fileId && source === 'email' && hasLinkResults;

  const isAudioFile = proof.fileCategory === 'audio';

  useEffect(() => {
    if (proof.fileId && source === 'email') {
      sdk.proofs.linkCheck.results(proof.id).then((res) => {
        setLinkCheckResults(res.data.results);
      });
    }
  }, [proof.id]);

  useEffect(() => {
    if (!isAudioFile) {
      const cachedMeasurementUnit = getCachedMeasurementUnit(sdk.session.userId);
      if (!cachedMeasurementUnit && UNITS[measurementUnits].isChangeable) {
        isBrowserTimezoneInCountry('US').then(((isUser) => {
          const initialMeasurementUnit = isUser ? UNITS.inch.unitValue : measurementUnits;
          setInitMeasurementUnit(initialMeasurementUnit);
          setSelectedUnit(initialMeasurementUnit);
        }));
      } else if (UNITS[measurementUnits].isChangeable) {
        setInitMeasurementUnit(cachedMeasurementUnit);
        setSelectedUnit(cachedMeasurementUnit);
      } else {
        setInitMeasurementUnit(measurementUnits);
        setSelectedUnit(measurementUnits);
      }
    }
  }, []);

  useEffect(() => {
    if (isAudioFile) {
      setDimensionLabelText('Audio file details');
    } else {
      const convertedDimensionData = getConvertedDimensionData(selectedUnit);

      setDimensionLabelText(generateDimensionLabel(convertedDimensionData));

      if (updatePageDimensionInfo) {
        updatePageDimensionInfo({
          ...convertedDimensionData,
          isDecimal: UNITS[convertedDimensionData.unit].isDecimal,
        });
      }
      if (UNITS[selectedUnit].isChangeable) {
        setCachedMeasurementUnit(sdk.session.userId, selectedUnit);
      }
    }
  }, [selectedUnit, currentPage, originalHeight, originalWidth]);

  return (
    <Popover
      content={popover => (
        <div className={css.ProofPageInfo__optionList}>
          <OptionList wrap={false}>
            {!isAudioFile && (
              <Fragment>
                <Option
                  label={<Translation value="proof.page.info.dimensions-size" />}
                  disabled
                />
                <Option
                  label={dimensionLabelText}
                  readOnly
                />
                {UNITS[initMeasurementUnit].isChangeable
                  ? (
                    <OptionChanger
                      options={
                        Object.keys(UNITS).map(key => UNITS[key])
                          .filter(({ unitValue, isChangeable, canDisplay }) => {
                            const { width, height } = getConvertedDimensionData(unitValue);
                            if (canDisplay && extension !== 'pdf' && fileType !== 'zip-Static') {
                              return false;
                            }
                            return isChangeable && (width >= 1 || height >= 1);
                          })
                          .map(({ unitLabel, unitValue }) => ({
                            label: <Translation
                              key={unitLabel}
                              value={`proof.page.info.unit.${unitLabel}`}
                            />,
                            value: unitValue,
                            default: unitValue === initMeasurementUnit,
                          }))
                      }
                      selected={selectedUnit}
                      onClick={setSelectedUnit}
                      selectedOptionIcon={<OptionChanger.Arrow />}
                    />
                  ) : (
                    <Fragment>
                      <Option
                        label={<Translation value={`proof.page.info.unit.${UNITS[selectedUnit].unitLabel}`} />}
                        readOnly
                      />
                    </Fragment>
                  )
                }
              </Fragment>
            )}
            {duration > 0 && isAudioFile && (
              <Fragment>
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.duration" />}
                  disabled
                />
                <Option
                  label={durationFormatter(duration)}
                  readOnly
                />
              </Fragment>
            )}
            {emailMetadata &&
              <EmailMetadata emailMetadata={emailMetadata} />
            }
            {showLinkChecker &&
            <LinkCheckResultsContainer
              proofId={proof.id}
              data={linkCheckResults}
            />
            }
            {crData && (
              <Fragment>
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.content-credentials" />}
                  disabled
                />
                <div style={{ width: '100%' }}>
                  <Option
                    label={<ContentCredentials
                      key={crData.title}
                      crData={crData}
                      showPopover={isCRPopoverOpen}
                      label={`${crData.title}`}
                      onClick={() => setIsCRPopoverOpen(!isCRPopoverOpen)}
                    />}
                  />

                </div>
              </Fragment>
            )}
            {tools && (
              <Fragment>
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.tools" />}
                  disabled
                />
                {tools.ruler && (
                  <Option
                    label={<Translation value="proof.page.info.ruler" />}
                    onClick={() => handleClickRuler(popover)}
                    checked={isActivatedRuler}
                  />
                )}
                {tools.gridLines && (
                  <Option
                    label={<Translation value="proof.page.info.grid-line" />}
                    onClick={() => handleClickGridLines(popover)}
                    checked={isActivatedGridLines}
                  />
                )}
                {(tools.ruler || tools.gridLines) && (
                  <Option
                    label={
                      <Translation
                        value="proof.page.info.scale"
                        params={{
                          inputScale: (
                            <Input
                              type="number"
                              max={maximumScale}
                              min={minimumScale}
                              step={minimumScale}
                              className={css.ProofPageInfo__scaleInput}
                              // A period at the end ('.') doesn't trigger the onChange event. For having enough space with it, added the 0.5ch.
                              style={{ width: `${(scale.toString().length || 1) + 0.5}ch` }}
                              value={scale}
                              onChange={handleChangeScale}
                              onBlur={onSubmitScale}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  onSubmitScale();
                                }
                                if (INVALID_SCALE_INPUT_CHARS.includes(event.key)) {
                                  // Input number allows to enter these chars (+,-,e,E), but these don't trigger the onChange event.
                                  // For avoiding these chars, added the condition.
                                  event.preventDefault();
                                }
                              }}
                            />
                          ),
                        }}
                      />
                    }
                    readOnly
                  />
                )}
              </Fragment>
            )}
            {fontFamilyList && fontFamilyList.length > 0 && (
              <Separator />
            )}
            <div className={css.ProofPageInfo__properties}>
              {fontFamilyList && fontFamilyList.length > 0 && (
                <Fragment>
                  <Option
                    label={<Translation value="proof.page.info.fonts" />}
                    disabled
                  />
                  {fontFamilyList.length <= THE_LIMIT_COUNT_FOR_DISPLAY
                    ? (fontFamilyList.map(fontName => (
                      <SubOptionList
                        key={fontName}
                        label={fontName}
                        options={fontFamilyWithStyles[fontName]}
                      >
                        <Option readOnly />
                      </SubOptionList>
                    ))
                    ) : (
                      <SubOptionList
                        label={<Translation
                          value="proof.page.info.view-fonts"
                          params={{
                            count: fontFamilyList.length,
                          }}
                        />}
                        options={fontFamilyList}
                        optionObjects={fontFamilyWithStyles}
                      >
                        <SubOptionList>
                          <Option readOnly />
                        </SubOptionList>
                      </SubOptionList>
                    )}
                </Fragment>
              )}
              {colorProfile && (
                <Fragment>
                  <Separator />
                  <Option
                    label={<Translation value="proof.page.info.colors.profile" />}
                    disabled
                  />
                  <Option
                    label={
                      <Tooltip
                        middle
                        right
                        title={colorProfile}
                        onBeforeOpen={onlyWhenTruncated(`.${css.ProofPageInfo__properties__label}`)}
                        offset={20}
                        delay={1000}
                      >
                        <div>
                          <div className={css.ProofPageInfo__properties__label}>
                            {colorProfile}
                          </div>
                        </div>
                      </Tooltip>}
                    readOnly
                  />
                </Fragment>
              )}
              {colorNames && colorNames.length > 0 && (
                <Fragment>
                  <Separator />
                  <Option
                    label={<Translation value="proof.page.info.colors" />}
                    disabled
                  />
                  {colorNames.map(value => (
                    <Option
                      key={value}
                      label={value}
                      readOnly
                    />
                  ))}
                </Fragment>
              )}
              {(linkedFiles && linkedFiles.length > 0) && (
                <Fragment>
                  <Separator />
                  <Option
                    label={<Translation value="proof.page.info.linked.files" />}
                    disabled
                  />
                  {linkedFiles.map(file => (
                    <Option
                      label={
                        <Tooltip
                          middle
                          right
                          title={file}
                          onBeforeOpen={onlyWhenTruncated(`.${css.ProofPageInfo__properties__label}`)}
                          offset={20}
                          delay={1000}
                        >
                          <div>
                            <div className={css.ProofPageInfo__properties__label}>
                              {file}
                            </div>
                          </div>
                        </Tooltip>}
                      readOnly
                    />
                  ))}
                </Fragment>
              )}
            </div>
            {duration > 0 && !isAudioFile && (
              <Fragment>
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.duration" />}
                  disabled
                />
                <Option
                  label={durationFormatter(duration)}
                  readOnly
                />
              </Fragment>
            )}
            {audioMetaData && (
              <Fragment>
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.bitrate" />}
                  disabled
                />
                <Option
                  label={audioMetaData.bitrate}
                  readOnly
                />
                <Separator />
                <Option
                  label={<Translation value="proof.page.info.sample-rate" />}
                  disabled
                />
                <Option
                  label={audioMetaData.sampleRate}
                  readOnly
                />
                {audioMetaData.channels && (
                  <Fragment>
                    <Separator />
                    <Option
                      label={<Translation value="proof.page.info.channels" />}
                      disabled
                    />
                    <Option
                      label={audioMetaData.channels}
                      readOnly
                    />
                  </Fragment>
                )}
                {audioMetaData.encoder && (
                  <Fragment>
                    <Separator />
                    <Option
                      label={<Translation value="proof.page.info.encoder" />}
                      disabled
                    />
                    <Option
                      label={audioMetaData.encoder}
                      readOnly
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </OptionList>
        </div>
      )}
      up
      center
      arrow
      offset={15}
      padding={false}
      onBeforeHide={() => {
        if (isFooterMouseover === false) {
          setKeepShowing(!keepShowing);
        }
        setIsCRPopoverOpen(isCRPopoverOpen ? false : isCRPopoverOpen);
      }}
    >
      <ShyTextPill
        delay={6000}
        className={css.ProofPageInfo__dimension}
        show={keepShowing || isFooterMouseover}
        onClick={() => {
          setKeepShowing(!keepShowing);
          setIsCRPopoverOpen(isCRPopoverOpen ? false : isCRPopoverOpen);
        }}
      >
        {dimensionLabelText}
        {pageCount > 1 && (
          <span>
            <Translation
              value="proof.dimensions-pill.page-number"
              params={{ pageNumber: currentPage }}
            />
          </span>
        )}
        {(linkCheckResults && hasLinkResults) &&
        <div className={css.ProofPageInfo__summary}>
          <LinkSummaryDot
            status={linkCheckResults.summary}
            hidePopover
          />
        </div>
        }
        {crData &&
          <div style={{ position: 'absolute',
            right: '-2px',
            top: '-9px' }}
          >
            <CRIcon />
          </div>
        }
        <InlineSVG
          className={classname(css.ProofPageInfo__dimension__arrow, {
            [css['ProofPageInfo__dimension__arrow--open']]: keepShowing,
          })}
          src="img/icons/material/symbols/expand_less-20px.svg"
        />
      </ShyTextPill>
    </Popover>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProofPageInfo.propTypes = {
    proof: PropTypes.shape({
      fontList: PropTypes.array,
      colorList: PropTypes.array,
      pageDimensions: PropTypes.array,
      pageCount: PropTypes.number,
    }),
    isFooterMouseover: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    audioMetaData: PropTypes.shape({
      duration: PropTypes.string, // Note that this is not accurate for mp3 files. Using waveform for duration.
      bitrate: PropTypes.string,
      sampleRate: PropTypes.string,
      channels: PropTypes.string,
      bitsPerSample: PropTypes.string,
      encoder: PropTypes.string,
    }),
    currentPage: PropTypes.number,
    tools: PropTypes.shape({
      ruler: PropTypes.bool,
      gridLines: PropTypes.bool,
    }),
    onChangeRulerMode: PropTypes.func,
    isActivatedRuler: PropTypes.bool,
    onChangeGridLinesMode: PropTypes.func,
    isActivatedGridLines: PropTypes.bool,
    updatePageDimensionInfo: PropTypes.func,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    measurementUnits: PropTypes.string,
    linkCheckResults: PropTypes.shape({ ...LinkCheckResultsContainer.propTypes }),
    pageDimensionInfo: PropTypes.shape({
      scale: PropTypes.number,
      minimumScale: PropTypes.number,
      maximumScale: PropTypes.number,
    }),
  };
}

export default ProofPageInfo;

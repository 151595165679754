/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const UNITS = {
  mm: {
    unitLabel: 'millimeters',
    unitValue: 'mm',
    formula: 'multiply',
    formulaNumber: 1,
    isChangeable: true,
    isDecimal: true,
  },
  cm: {
    unitLabel: 'centimeters',
    unitValue: 'cm',
    formula: 'divide',
    formulaNumber: 10,
    isChangeable: true,
    isDecimal: true,
  },
  m: {
    unitLabel: 'meters',
    unitValue: 'm',
    formula: 'divide',
    formulaNumber: 1000,
    isChangeable: true,
    isDecimal: true,
  },
  inch: {
    unitLabel: 'inches',
    unitValue: 'inch',
    formula: 'divide',
    formulaNumber: 25.4,
    isChangeable: true,
    isDecimal: true,
  },
  ft: {
    unitLabel: 'feet',
    unitValue: 'ft',
    formula: 'divide',
    formulaNumber: 304.8,
    isChangeable: true,
    isDecimal: true,
  },
  pt: {
    unitLabel: 'point',
    unitValue: 'pt',
    formula: 'multiply',
    formulaNumber: 2.835,
    isChangeable: true,
    isDecimal: true,
  },
  px: {
    unitLabel: 'pixel',
    unitValue: 'px',
    isChangeable: false,
    isDecimal: false,
  },
  pxW: {
    unitLabel: 'web-pixel',
    unitValue: 'pxW',
    label: 'px',
    formula: 'multiply',
    formulaNumber: 2.835,
    isChangeable: true,
    isDecimal: true,
    canDisplay: true,
  },
  pxP: {
    unitLabel: 'print-pixel',
    unitValue: 'pxP',
    label: 'px',
    formula: 'multiply',
    formulaNumber: 11.811,
    isChangeable: true,
    isDecimal: true,
    canDisplay: true,
  },
};

export default UNITS;

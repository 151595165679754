/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Translation from '../../Text/Translation';
import css from './ShowReply.scss';
import Replies from '../Icon/Replies';

function ShowReply({ onToggleReplyEditor, isCondensed }) {
  return (
    isCondensed ? (
      <Replies
        size={20}
        onClick={onToggleReplyEditor}
        className={css.Icon}
      />
    ) : (
      <div
        className={css.Link}
        onClick={onToggleReplyEditor}
        aria-label="Reply"
        tabIndex={0}
      >
        <Translation value="button.reply" />
        <span className={css.Elipsis}>...</span>
      </div>
    )
  );
}

ShowReply.propTypes = {
  onToggleReplyEditor: PropTypes.func,
  isCondensed: PropTypes.bool,
};

export default ShowReply;

/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
(function () {
    var self = this;
    ['warn', 'log', 'info', 'error', 'foobar'].forEach(function (name) {
        var console = (self.console = self.console || {});
        console[name] = console[name] || function() {};
    });
}).call(this);

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign != 'function') {
    Object.assign = function(target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource != null) { // Skip over if undefined or null
                for (var nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    };
}

var append = deprecated(function append(id, html){
    $(id).append(html);
}, 'Use `angular.element(element).append(html)` instead.');

function prepend(id, html){
    $(id).prepend(html);
}

var redir = deprecated(function redir(loc){
    document.location.href = loc;
}, 'Use `$location.url(href)` or `$location.path(href)` instead.');

function val(id, html){
    return $(id).val();
}

function showLoader(id){
    $("#spinner_" + id).show();
}

function showError(fieldId, errorMessage, toggle){
    if(toggle == 'hide'){
        $(fieldId + "_error").hide();
    }else{
        if(isset(errorMessage)){
            $(fieldId + "_error").html(errorMessage);
        }
        $(fieldId + "_error").show();
    }
}

function hideLoader(id){
    $("#spinner_" + id).hide();
}

var onClick = deprecated(function onClick(selector, callback){
    $(selector).on("click", callback);
}, 'Use `element.addEventListener("click", callback, false)` or `angular.element(element).on("click", callback)` instead.');

var on = deprecated(function on(onType, selector, callback){
    $(selector).on(onType, callback);
}, 'Use `element.addEventListener("event", callback, false)` or `angular.element(element).on("event", callback)` instead.');

function getValue(selector){
    return $(selector).val();
};

var show = deprecated(function show(selector){
    $(selector).show();
}, 'Use `element.style.display = ""` or `angular.element(element).show()` instead.');

var hide = deprecated(function hide(selector){
    $(selector).hide();
}, 'Use `element.style.display = "none"` or `angular.element(element).hide()` instead.');

var remove = deprecated(function remove(selector){
    $(selector).remove();
}, 'Use `element.remove()`, `element.parentElement.removeChild(element)` or `angular.element(element).remove()` instead.');

function pop(array){
    return array[array.length - 1];
};

function isset(variable){
    if( ( variable !== false ) && ( variable !== "" ) && ( typeof variable !== 'undefined' ) && ( variable !== null ) ){
        return true;
    }else{
        return false;
    }
};

function empty(variable){
    if(!isset( variable )){
        return true;
    }else{
        return false;
    }
};

function isObject(object){
    if (typeof object === "object") {
        return true;
    }else{
        return false;
    }
};

var count = deprecated(function count(object){
    var count = 0;
    if(typeof object === "object"){
        for (var item in object) {
            if (object.hasOwnProperty(item)) {
               ++count;
            }
        }
    }else if(typeof object === "array"){
        while(count < object.length){
            count++;
        }
    }
    return count;
}, 'Use `arr.length` or `Object.keys(obj).length` instead.');

function inArray(array, searchItem){
    if(isset(array) && isset(searchItem)){
        if($.inArray(searchItem, array) == "-1"){
            return false;
        }else{
            return true;
        }
    }else{
        return true;
    }
};

//used only at one place in datetime.calculate method, can replace that in remove it then
var inArrayReturnIndex = deprecated(function inArrayReturnIndex(array, searchItem){
    if(isset(array) && isset(searchItem)){
        if($.inArray(searchItem, array) == "-1"){
            return false;
        }else{
            return $.inArray(searchItem, array);
        }
    }else{
        return true;
    }
}, 'Use `arr.indexOf(obj)` instead.');


/**
 * Turns an array (or object's values) into a URI safe segmented string.
 *
 * Please note: it also includes a trailing slash (for the sake of .NETs routing).
 *
 * @example
 *     chain(['foo', 'bar']) === 'foo/bar/'
 *     chain({ first: 'foo', second: 'bar' }) === 'foo/bar/'
 *     chain([')(*', '@#$']) === ')(*\/%40%23%24/'
 *
 * @param {Array|Object} obj
 * @returns {String}
 */
function chain (obj) {
    var params = [];

    // If the `obj` is a type of Array - add each value as a param
    if (Array.isArray(obj)) {
        params = params.concat(obj);
    }

    // If the `obj` is an Object (check by casting to an Object making sure the value
    // is still the same as the original value - iterate over each of the items, and
    // add each value as a param.
    else if (Object(obj) === obj) {
        Object.keys(obj).forEach(function (key) {
            params.push(obj[key]);
        });
    }

    // Normalise the values of the parameters (converting to URI safe format)
    params = params.map(function (value) {
        return encodeURIComponent(value);
    });

    // Join the parameters by a slash
    return params.join('/') + '/';
}

function ucFirst(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function jsonDecode(jsonStr){
    return JSON.parse(jsonStr);
};

function jsonEncode(jsonStr){
    return JSON.stringify(jsonStr);
};

function strstr(haystack, needle, bool) {
    var pos = 0;
    haystack += "";
    pos = haystack.indexOf(needle);
    if (pos == -1) {
        return false;
    } else {
        if (bool) {
            return haystack.substr(0, pos);
        } else {
            return haystack.slice(pos);
        }
    }
};

var log = deprecated(function log(variable){
    if(true){
        console.log(variable);
    }
}, 'Please use `console.log(variable)` instead.');

var Log = deprecated(function Log(variable){
    if(true){
        console.log(variable);
    }
}, 'Please use `console.log(variable)` instead.');

function getFileType(fileName){
    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
}

function getFileName(fileName){
    return fileName.substring(0, fileName.lastIndexOf('.'));
}

function bytesToSize(bytes, decimalPlaces) {
   if(bytes == 0) return 'Empty';
   var k = 1000;
   var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   var i = Math.floor(Math.log(bytes) / Math.log(k));
   var num = (bytes / Math.pow(k, i));
   if (decimalPlaces === false) {
     num = Math.round(num);
   } else {
     num = num.toPrecision(3).replace(/\.00$/, '');
   }
   return num + ' ' + sizes[i];
}

function getEmailArr(emailStr) {
    var emails = [];
    if (emailStr.indexOf(',') !== -1 || emailStr.indexOf(';') !== -1) {
        emails = emailStr.split(/\s*,\s*|\s*;\s*/);
    } else {
        emails.push(emailStr);
    }
    return emails;
}

function repeatAsyncForEach(items, callback) {
    var index = 0;
    var next = function() {
        var item = items[index++];
        if (index - 1 < items.length) {
            callback(item, next, index);
        }
    };
    next();
}

function validateEmail(emailAddress) {
    return validateEmail.match.test(emailAddress);
}

validateEmail.match = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function truncateStr(str, len){
    return str.length>len ? str.substr(0,len-1)+'&hellip;' : str;
}

var random = deprecated(function(){
    return Math.random();
}, 'Use `Math.random()` instead.');

var deferUi = function( _type, _$elem, _initVal, _callback , exspectedStr) {

    var tries = 1;

    var getValue = function(){
        switch(_type){
            case 'input':
                return _$elem.val();
                break;
            case 'text':
                return _$elem.text();
                break;
            case 'img':
                return _$elem.prop("src");
                break;
            default:
                break;
        }
    };

    var interval = setInterval(function(){

        if(isset(exspectedStr)){
            if ( ( getValue() != "" && ( getValue() == exspectedStr) && getValue() != _initVal ) || (tries == 5) ) {

                clearInterval(interval);
                _callback();

            }else{
                tries ++;
            }
        }else {
            if (( getValue() != "" ) && getValue() != _initVal) {

                clearInterval(interval);
                _callback();

            }
        }
    }, 500);

};

var getUrlValue = function(){
    var currentUrl = (document.URL);
    var urlArray = currentUrl.split("/");
    var lastValue = urlArray[urlArray.length - 1];
    return lastValue;
};

var isFunc = undeprecated(function isFunc(func){
    if(typeof func === "function"){
        return true;
    }
    return false;
}, 'There is NO point in writing: `typeof func === "function"` when you can write: `isFunc(my_function)` instead.');

var getFirstLetterCaps = function(str){
    return str.charAt(0).toUpperCase();
}

var getUserInitials = function (User) {
    return function () {
        // If there is no user object passed in
        if ( ! User) {
            return '';
        }

        // If a string is passed through, use the first character
        if (typeof User === 'string') {
            return User.substring(0, 1);
        }

        if (User.Name && User.Name.length) {
            //// Does the user have both a first and last name?
            //var hasFirstAndLast = User.Name.search(/\s/) !== -1;
            //
            //if (hasFirstAndLast) {
            //    var names = User.Name.split(/\s+/);
            //
            //    // Both first and last initial (of name)
            //    return (names[0].charAt(0) + names[names.length - 1].charAt(0));
            //}

            // First initial (of name)
            return User.Name.charAt(0);
        }

        if (User.Email && User.Email.length) {
            // First ~~two~~ letter of email address
            return User.Email.substring(0, 1);
        }

        return '';
    }().toUpperCase();
};

var debounce = function (func, wait, immediate) {
    var timeout, args, context, timestamp, result;

    var later = function() {
        var last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if ( ! immediate) {
                result = func.apply(context, args);
                if ( ! timeout) context = args = null;
            }
        }
    };

    return function () {
        context = this;
        args = arguments;
        timestamp = Date.now();

        var callNow = immediate && ! timeout;
        if ( ! timeout) timeout = setTimeout(later, wait);

        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
};

var throttle = function (func, wait, options) {
    var context, args, result,
        timeout = null,
        previous = 0;

    if ( ! options) options = {};

    var later = function() {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if ( ! timeout) context = args = null;
    };

    return function() {
        var now = Date.now();
        if ( ! previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);

        context = this;
        args = arguments;

        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }

            previous = now;
            result = func.apply(context, args);

            if ( ! timeout) context = args = null;
        } else if ( ! timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }

        return result;
    };
};

function noop () {
    // Do nothing...
}

function once (fn) {
    var done = false;

    return function () {
        if ( ! done) {
            done = true;

            fn.apply(this, arguments);
        }
    };
}

function todo (fn, message) {
    var name = getFunctionSignature(fn),
        warning = once(function () {
            console.warn('TODO warning: %s is incomplete or may lack completed functionality. ' + (message || ''), name);
        });

    return function () {
        warning();

        return fn.apply(this, arguments);
    };
}

function generalfunctions_canSkipAll (proofStatus, isLastStepVisible) {
    return (proofStatus === 10 || proofStatus === 30) && !isLastStepVisible;
}

function deprecated (fn, message) {
    var name = getFunctionSignature(fn),
        warning = once(function () {
            if (typeof message === 'function') message = message();
            console.warn('Deprecation warning: %s is deprecated. ' + (message || ''), name);
        });

    return function () {
        warning();

        return fn.apply(this, arguments);
    };
}

function undeprecated (fn, message) {
    var name = getFunctionSignature(fn),
        warning = once(function () {
            if (typeof message === 'function') message = message();
            console.warn('Un-deprecation notice: %s is no longer deprecated. ' + (message || ''), name);
        });

    return function () {
        warning();

        return fn.apply(this, arguments);
    };
}

function stringToArrayBuffer (str) {
    var arrayBuffer = new ArrayBuffer(str.length),
        intArray = new Uint8Array(arrayBuffer);

    for (var index = 0; index < str.length; index++) {
        intArray[index] = str.charCodeAt(index);
    }

    return arrayBuffer;
}

function stripTags(html){
    if (typeof html === 'string') {
        return html.replace(/<(?!br\s*\/?)[^>]+>/g, ''); //strips everything but the <br> <br /> tags
    } else {
        return "";
    }
}

function dataURLToBlob (url) {
    var meta = url.substring(0, url.indexOf(',')),
        mime = meta.substring('data:'.length, meta.indexOf(';')),
        encoding = meta.substring(meta.indexOf(';') + 1),
        data = url.substring(url.indexOf(',') + 1),
        normalised;

    switch (encoding) {
        case 'base64':
            normalised = atob(data); //atob
            break;
        default:
            normalised = data;
            break;
    }

    return new Blob(
        [stringToArrayBuffer(normalised)], {
            type: mime
        }
    );
}

function blobToDataURL (blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
        callback(reader.result);
    };
    reader.onerror = function () {
        callback(null);
    };
    reader.readAsDataURL(blob);
}

function getArgumentNames (fn) {
    var str = fn.toString().replace(/((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg, '');
    return str.slice(str.indexOf('(') + 1, str.indexOf(')')).match(/([^\s,]+)/g) || [];
}

function getFunctionSignature (fn) {
    return (fn.name ? fn.name.replace('__', '.') : 'anonymous') + '(' + getArgumentNames(fn).join(', ') + ')';
}

function censor (data) {
    var canClone = data && Object(data) === data || Array.isArray(data),
        clone = canClone ? new data.__proto__.constructor : undefined;

    if (canClone) {
        Object.keys(data).forEach(function (key) {
            var value = data[key],
                matches = censor.blacklist.filter(function (text) {
                    return key.toLowerCase().indexOf(text) !== -1;
                });

            console.log(key, matches);

            if (matches.length) {
                value = 'censored ' + censor.value(value);
            } else {
                value = censor(value);
            }

            clone[key] = value;
        });

        return clone;
    } else {
        return data;
    }
}

/**
 * Returns a placeholder value for a specific object.
 *
 * @param {*} obj
 * @returns {String}
 */
censor.value = function (obj) {
    var _type = Array.isArray(obj) ? 'array' : typeof obj;

    switch (_type) {
        case 'string':
            return '(String length=' + obj.length + ')';
        case 'number':
            return '(Number isNumber=' + (! isNaN(obj)) + ')';
        case 'array':
            return '(Array length=' + obj.length + ')';
        case 'object':
            return '(Object keys=' + Object.keys(obj).length + ')';
        default:
            return '(?)';
    }
};

/**
 * The list of properties we want to remove from censored objects.
 * These keys are lowercase, to provide case insensative matching.
 *
 * @type {Array}
 */
censor.blacklist = [
    'secret',
    'password',
    'privatekey',
    'publickey'
];

var env = function (registry) {
    if (this.window && this.window.angular) {
        window.angular.element('meta[data-env]').each(function () {
            registry[this.name] = this.getAttribute('data-env');
        });
    }

    return function (name, def) {
        if (name in registry) {
            return registry[name];
        } else {
            return def;
        }
    };
}.call(this, {});

function stringifyElement (element, level) {
    if (typeof level === 'undefined') level = 0;

    var padding = Array(level).join('    ');

    var str = padding + '<' + element.nodeName.toLowerCase();

    [].slice.apply(element.attributes).forEach(function (attr) {
        str += ' ' + attr.nodeName.toLowerCase() + '="' + attr.nodeValue + '"';
    });

    str += '>';

    [].slice.apply(element.children).forEach(function (child) {
        str += '\n' + stringifyElement(child, level + 1);
    });

    str += '\n' + padding + element.textContent.replace('\n', '\n' + padding + '    ') + '';

    str += '\n' + padding + '</' + element.nodeName.toLowerCase() + '>';

    return str;
}

function focus(id){
    var inputField = document.getElementById(id);
    if (inputField != null && inputField.value.length != 0){
        if (inputField.createTextRange){
            var FieldRange = inputField.createTextRange();
            FieldRange.moveStart('character',inputField.value.length);
            FieldRange.collapse();
            FieldRange.select();
        }else if (inputField.selectionStart || inputField.selectionStart == '0') {
            var elemLen = inputField.value.length;
            inputField.selectionStart = elemLen;
            inputField.selectionEnd = elemLen;
            inputField.focus();
        }
    }else{
        inputField.focus();
    }
}

function createError (name) {
    function CustomError (message) {
        if ( ! (this instanceof CustomError)) {
            return new CustomError(message);
        }
        Error.apply(this, arguments);
        this.message = (message || '');
    }
    CustomError.prototype = Object.create(Error.prototype);
    CustomError.prototype.constructor = CustomError;
    CustomError.prototype.name = name;
    CustomError.prototype.toString = function () {
        return name + ': ' + this.message;
    };
    try {
        CustomError.name = name;
        Object.defineProperty(CustomError, 'name', { value: name });
    } catch (err) {}
    return CustomError;
}

(function (global) {
    global.TodoError = createError('TodoError');
})(self || window);

function numbers (count) {
    return Array.apply(null, Array(count)).map(function (_, index) { return index; });
}

if ( ! ('startsWith' in String.prototype)) {
    String.prototype.startsWith = function startsWith (str, start) {
        start = start || 0;
        return this.indexOf(str, start) === start;
    };
}

var $$log = (function () {
    try {
        var find = function (base, str) {
            return base[str.split(' ').map(function (code) {
                return String.fromCharCode(Number(code));
            }).join('')];
        };

        var _console = '99 111 110 115 111 108 101',
            _log = '105 110 102 111'; // log: 108 111 103
                                      // info: 105 110 102 111

        var console = find(window, _console);
        return find(console, _log).bind(console);
    } catch (err) {
        return function () {};
    }
})();

$$log.create = function (prefix) {
    prefix = '[' + prefix + ']';

    return function () {
        var message = [].slice.apply(arguments);

        if (angular.isString(message[0])) {
            message[0] = prefix + ' ' + message[0];
        } else {
            message.unshift(prefix);
        }

        $$log.apply(this, message);
    }
};

function provide ($injector, Provider, Service) {
    var provider = $injector.instantiate(Provider);
    provider.$get = ['$injector', function ($injector) {
        return $injector.instantiate(Service, { provider: provider });
    }];
    return provider;
}

/**
 * @param {Object<String, Number>} outer
 * @param {Object<String, Number>} box
 * @param {Object<String, Number>} video
 * @returns {Object<String, Number>}
 */
function containBox (outer, box) {
    if (box && box.ratio && box.ratio < (outer.width / outer.height)) {
        var width = Math.ceil(outer.height * box.ratio * 2) / 2;
        return {width: width || 'auto', height: outer.height}
    } else {
        var ratio = Math.min(outer.width / box.width, outer.height / box.height);
        return { width: box.width * ratio, height: Math.floor(box.height * ratio) };
    }
}

function between (num, min, max) {
    return Math.max(min, Math.min(max, num));
}

/**
 * Toggle the state of an item within an array.
 *
 * @param {*[]} arr
 * @param {*} value
 * @returns {Number}
 */
function toggleValueInArray (arr, value) {
    var index;

    if ((index = arr.indexOf(value)) !== -1) {
        arr.splice(index, 1);
    } else {
        index = arr.push(value);
    }

    return index;
}

/**
 * Returns a sorting function for objects in an array.
 *
 * @param {String} key
 * @param {Boolean} [reverse]
 * @returns {Function}
 */
function sortObjectArrayFn (key, reverse) {
    var moveUp = reverse ? 1 : -1,
        moveDown = reverse ? -1 : 1;
    return function (a, b) {
        if (a[key] < b[key]) {
            return moveUp;
        } else if (a[key] > b[key]) {
            return moveDown;
        } else {
            return 0;
        }
    };
}

function generalfunctions_unique(arr) {
    return arr.reduce(function(accum, current) {
        if (accum.indexOf(current) < 0) {
            accum.push(current);
        }
        return accum;
    }, []);
}

function generalfunctions_getUniqueArrayItems (array, key) {
  return array.reduce(function(accum, current) {
    var index = accum.findIndex(function(e) {
      return e[key] === current[key];
    });
    if (index < 0) {
      accum.push(current);
    }
    return accum;
  }, []);
}


/**
 * Returns a url with an anti-cache query string appended to the end.
 *
 * @param {String} url
 * @returns {String}
 */
function antiCache (url) {
    if (angular.isString(url)) {
        return (
            url +
            (url.indexOf('?') === -1 ? '?' : '&') +
            '_ppxach=' + PageProof.hash // PageProof X anti-cache hash
        );
    } else {
        return url;
    }
}

/**
 *
 * @example
 *     getPercentage(.5, .1, .1) => .5
 *     getPercentage(0, .1, .1) => .1
 *     getPercentage(1, .1, .1) => .9
 *
 * @param {Number} percent
 * @param {Number} left
 * @param {Number} right
 */
function getPercentage (percent, left, right) {
    var total = 1 - (left + right);
    return (total * percent) + left;
}

/**
 * Resize a blob.
 *
 * @param {Blob|String} blob
 * @param {Object|Number} size
 * @param {Function<Blob>} callback
 * @param {boolean} square
 */
function resizeImageBlob (blob, size, callback, square) {
    var isString = typeof blob === 'string',
        url = isString ? blob : URL.createObjectURL(blob);
    PageProof.Image.render(url, size, function (dataUrl) {
        var blob = dataURLToBlob(dataUrl);
        callback(blob);
        if ( ! isString) URL.revokeObjectURL(url);
    }, square);
}

function calculateImageDimensions(url, callback) {
    var image = new Image();
    image.addEventListener('load', function () {
        callback(null, {width: image.naturalWidth, height: image.naturalHeight});
        image = null;
    });
    image.addEventListener('error', function () {
        callback(Error('Unable to calculate image dimensions (' + url + ')'));
        image = null;
    });
    image.src = url;
}

/**
 * @example
 *     createEnumeration([
 *         'ONE',
 *         'TWO',
 *         'THREE'
 *     ]);
 *
 * @example
 *     createEnumeration({
 *         ONE: 1,
 *         TWO: 2,
 *         THREE: 4
 *     });
 *
 * @param {Object[]|Object<String, *>} info
 * @returns {Object<String, String>}
 */
function createEnumeration (info) {
    var _enum = {};
    if (Object.prototype.toString.call(info) === '[object Object]') {
        Object.keys(info).forEach(function (key) {
            _enum[key] = info[key];
        });
    } else {
        for (var index = 0; index < info.length; index++) {
            _enum[info[index]] = info[index].toLowerCase().replace(/_/g, '-');
        }
    }
    return _enum;
}

function inspect () {
    console.log.apply(console, [].slice.call(arguments));
    return arguments[0];
}

try {
    var cropImage__canvas = document.createElement('canvas');
    var cropImage__ctx = cropImage__canvas.getContext('2d');
} catch (err) {
    // Ignore this
}
function cropImage (image, x, y, width, height) {
    if (cropImage__canvas && cropImage__ctx) {
        cropImage__canvas.width = width;
        cropImage__canvas.height = height;
        cropImage__ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
        var dataUrl = cropImage__canvas.toDataURL('image/png');
        var blob = dataURLToBlob(dataUrl);
        return URL.createObjectURL(blob);
    } else {
        throw new Error('Attempt to crop an image within a context without a document.');
    }
}

function getSpriteSheetPosition(pageNumber, spriteSheetCols) {
    var pageIndex = pageNumber - 1,
        x = pageIndex % spriteSheetCols,
        y = Math.floor(pageIndex / spriteSheetCols);
    return {x: x, y: y};
}

(function (self) {
    var prefix = {
        Proof: 'P',
        Brief: 'B',
        Workflow: 'W',
        File: 'F',
        User: 'U',
        Group: 'G',
    };

    Object.keys(prefix).forEach(function (name) {
        self['is' + name + 'Id'] = function(id) {
            return id && id.length === 16 && id[0] === prefix[name];
        };
    });
})(this);

/**
 * Provides a way of caching a specific output based on an input.
 *
 * Note: This function is not safe in WebWorkers (it makes use of `angular.equals`).
 *
 * @param {Function} input
 * @param {Function} output
 * @returns {Function}
 */
function cachedIO (input, output) {
    var latestInput, latestOutput;

    return function getCachedOutput () {
        var currentInput = input(latestOutput);

        if ( ! angular.equals(currentInput, latestInput)) {
            latestOutput = output(currentInput);
            latestInput = currentInput;
        }

        return latestOutput;
    };
}

/**
 * Invoke a method with the app's $injector instance.
 *
 * @type {Function}
 */
var injectorInvoke = (function (app) {
    if ( ! app) return function injectorInvokeError () {
        throw new Error('Cannot use `injectorInvoke` within a web worker.');
    };

    var injector;
    app.run(['$injector', function ($injector) {
        injector = $injector;
        injectorInvoke.$injector = $injector;
    }]);

    function injectorInvoke (fn, that, locals) {
        return injector.invoke([].concat(
            injector.annotate(fn), fn
        ), that, locals);
    }

    return injectorInvoke;
})(this.app);

var $downloadFileNode;
function downloadFile(name, blob) {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
    } else {
        $downloadFileNode = $downloadFileNode || document.createElement('a');
        document.body.appendChild($downloadFileNode);
        $downloadFileNode.download = name;
        var url = $downloadFileNode.href = URL.createObjectURL(blob);
        $downloadFileNode.click();
        setTimeout(function() {URL.revokeObjectURL(url)});
    }
}

function downloadURL(url, name) {
    var beforeUnload = window.onbeforeunload;
    window.onbeforeunload = null;
    var node = document.createElement('a');
    node.href = url;
    node.download = name;
    node.click();
    window.onbeforeunload = beforeUnload;
}

function randomString(length, chars) {
    var mask = '';
    var all = chars === '*';
    if (chars.indexOf('a') > -1 || all) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1 || all) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1 || all) mask += '0123456789';
    if (chars.indexOf('!') > -1 || all) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
}

function stripText(text) {
    var temp = document.createElement('div');
    temp.textContent = text;
    var stripped = temp.innerHTML;
    temp = null;
    return stripped;
}

// https://example.com/js/main.js.map?foobar => /js/main.js.map
function getURLPath(url) {
    if (!url || !String(url).match(/^(http|ws)s?:/)) {
        return url; // probably not a real URL
    }
    var protocolEndIndex = url.indexOf('://') + '://'.length;
    var pathStartIndex = url.substring(protocolEndIndex).indexOf('/');
    var pathEndIndex = ((url.indexOf('?') + 1) || (url.indexOf('#') + 1)) - 1;
    if (pathEndIndex === -1) {
        pathEndIndex = url.length;
    }
    return url.substring(protocolEndIndex + pathStartIndex, pathEndIndex);
}

function spacer(width, height, backgroundColor) {
    if (typeof height === 'string') {
        backgroundColor = height;
        height = width;
    }
    if (typeof height === 'undefined') {
        height = width;
    }
    if (typeof backgroundColor === 'undefined') {
        backgroundColor = 'transparent';
    }
    var key = width + 'x' + height + '@' + backgroundColor;
    if (spacer.cache.hasOwnProperty(key)) {
        return spacer.cache[key];
    }
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    if (backgroundColor !== 'transparent') {
        var ctx = canvas.getContext('2d');
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, width, height);
    }
    var dataURL = canvas.toDataURL('image/png');
    var blob = dataURLToBlob(dataURL);
    var url = URL.createObjectURL(blob);
    canvas = ctx = dataURL = null;
    spacer.cache[key] = url;
    return url;
}
spacer.cache = {};

function randomId(type) {
    return type + btoa(randomString(32, '*'))
            .replace(/[\/+]/g, '')
            .substring(0, 15)
            .toUpperCase()
}

function getFilledArray(size, dataToFill) {
    var array = [];
    for (i = 0; i < size; i++) {
        array.push(dataToFill);
    }
    return array;
}

function generalfunctions_waitUntilPageLoaded() {
    if (document.readyState === 'complete') {
        return Promise.resolve();
    }
    return new Promise(function (resolve) {
        window.addEventListener('load', resolve);
    });
}

var detectExtension = (function () {
    var hook;
    return function detectExtension() {
        if (!hook) {
            // The hook system isn't available when this file loads, so we'll lazy initialize this hook.
            hook = window.__pageproof_hooks__.hookable('detect-browser-extension-presence', function () {
                return false;
            });
        }
        return hook().then(function (isInstalled) {
            if (isInstalled === false) {
                return (
                    // The extension registers itself on "document_idle" which at the very least, registers itself
                    // immediately after the onload event is triggered.
                    generalfunctions_waitUntilPageLoaded()
                    // This means we want to re-check after the extension has had a chance to finish initializing.
                    // The easiest way to do that is to sleep for the shortest possible period of time.
                    .then(function () { return generalfunctions_sleep(0); })
                    // Now finish by calling the hook again - if the extension is installed, it should always be
                    // registered at this point in time.
                    .then(hook)
                );
            }
            return isInstalled;
        });
    };
})();

function objectValues(obj) {
    if (obj == null || Object.prototype.toString.call(obj) !== '[object Object]') {
        return [];
    }
    var values = [];
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            values.push(obj[key]);
        }
    }
    return values;
}

function generalfunctions_parseUrl(url) {
    var el = document.createElement('a');
    el.href = url;
    var parsed = {
        href: el.href,
        origin: el.origin,
        protocol: el.protocol,
        host: el.host,
        hostname: el.hostname,
        port: el.port,
        pathname: el.pathname,
        search: el.search,
        hash: el.hash,
        username: el.username,
        password: el.password,
    };
    el = null;
    return parsed;
}

function generalfunctions_setUriCredentials(url, username, password) {
    var search = '://';
    var startIndex = url.indexOf(search) + search.length;
    var encodedUsername = encodeURIComponent(username);
    var encodedPassword = encodeURIComponent(password);
    return (
        url.substring(0, startIndex) +
        encodedUsername +
        (encodedPassword ? (':' + encodedPassword) : '') +
        ((encodedUsername || encodedPassword) ? '@' : '') +
        url.substring(startIndex)
    );
}

function generalfunctions_parseCommentText(commentText) {
    if (commentText === null || commentText === undefined) {
        commentText = ' ';
    }
    return (
        (new window.__pageproof_quark__.sdk.Model.Comment)
            .fromRawComment(commentText)
    );
}

function generalfunctions_getCommentLabel(value) {
    if (!value) {
        return null;
    }
    var tokens = window.generalfunctions_parseCommentText(value).tokens;
    var text = window.__pageproof_quark__.sdk.util.comments.text(tokens);
    var matches = text.match(/^(Approved|Highlight):/i);
    if (matches !== null) {
        return matches[1].toLowerCase();
    }
    return null;
}

function generalfunctions_preloadImage(url, callback) {
    var image = new Image();
    image.onload = function() {
        callback(null, image);
    };
    image.onerror = function() {
        callback(new Error('failed to preload image (' + url + ')'));
    };
    image.src = url;
}

function generalfunctions_preloadImagePromise(url) {
    return new Promise(function (resolve, reject) {
        generalfunctions_preloadImage(url, function (err, image) {
            if (err) {
                reject(err);
            } else {
                resolve(image);
            }
        });
    });
}

function generalfunctions_hasIntervalPassed(timeToCompare, interval) {

    var utcDate = timeToCompare;

    var date = new DateTime();
    var byPass = false;
    var diff = 0;
    var wait = 0;

    if (utcDate){
        diff = date.getDiff(utcDate, "minutes");
        wait = interval;
    } else {
        byPass = true;
    }

    if (diff > wait || byPass) {
        return true;
    } else {
        return false;
    }

}

function generalfunctions_preloadQuarkChunk(name) {
    if (window.__pageproof_quark_preloads__ &&
        window.__pageproof_quark_preloads__[name]) {
        return window.__pageproof_quark_preloads__[name]();
    }
    return Promise.reject(new Error(name + ' quark chunk cannot be preloaded - does not exist, or hasn\'t loaded yet'));
}

function generalfunctions_diff(previous, current) {
    function stringify(value) {
        if (typeof value === 'function') {
            var args = value.toString().match(/\(.*\)/);
            args = args ? args[0] : '()';
            return 'f ' + (value.name || '<anonymous>') + ' ' + args + ' { [code] }';
        } else {
            return JSON.stringify(value);
        }
    }
    var _log = ['  {'];
    var containsChanges = false;
    Object.keys(previous).concat(Object.keys(current))
        .filter(function (key, index, arr) { return arr.indexOf(key) === index; })
        .forEach(function (key) {
            if (previous[key] !== current[key]) {
                containsChanges = true;
                if (key in previous) {
                    _log.push('-   "' + key + '": ' + stringify(previous[key]) + ',');
                }
                if (key in current) {
                    _log.push('+   "' + key + '": ' + stringify(current[key]) + ',');
                }
            } else {
                _log.push('    "' + key + '": ' + stringify(current[key]) + ',');
            }
        });
    _log.push('  }');
    if (containsChanges) {
        return _log.join('\n');
    } else {
        return 'no diff';
    }
}

function generalfunctions_sleep(ms) {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms);
    });
}

function generalfunctions_parseSearchParams(url) {
    var searchParams = {};
    var instance = new URL(url, 'https://fake/');
    var queryString = instance.search.substring(1);
    if (!queryString) {
        return searchParams;
    }
    queryString.split(/&/g).forEach(function (segment) {
        var parts = segment.split('=');
        var name = decodeURIComponent(parts.shift());
        var value = decodeURIComponent(parts.join('='));
        if (value) {
            searchParams[name] = value;
        }
    });
    return searchParams;
}

function generalfunction_copyToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

function generalfunctions_parseDueDateInReminderHours(date) {
    var dateService = window.__pageproof_bridge__.dateService;
    var parsedDate = dateService.parse(date);
    var dateToday = new Date().getTime();
    var dateDue = parsedDate._d.getTime();
    var difference = Math.floor((dateDue - dateToday) / 1000 / 60);

    if (difference < -2880) {
        return -72;
    }
    if (difference >= -2880 && difference < -1440) {
        return -48;
    }
    if (difference >= -1440 && difference < 0) {
        return -24;
    }
    if (difference >= 0 && difference < 60) {
        return 0;
    }
    if (difference >= 60 && difference < 180) {
        return 1;
    }
    if (difference >= 180 && difference < 1440) {
        return 3;
    }
    if (difference >= 1440 && difference < 2880) {
        return 24;
    }
    if (difference >= 2880 && difference < 4320) {
        return 48;
    }
    if (difference >= 4320) {
        return 72;
    }
}

function generalfunctions_getLanguagePluralKey(count, keys) {
  switch (count) {
    case 0:
      return keys.none;
    case 1:
      return keys.single;
    default:
      return keys.multi;
  }
}

var generalfunctions_csv = (function() {
    function keys(obj) {
      return mapObject(obj, function(value, key) {
        return key;
      });
    }
    function assign(obj) {
      var objs = arguments;
      for (var i = 0; i < objs.length; i += 1) {
        mapObject(objs[i], function(value, key) {
          obj[key] = value;
        });
      }
      return obj;
    }
    function mapObject(obj, map) {
      var results = [];
      if (obj) {
        for (var key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            results.push(map(obj[key], key, obj));
          }
        }
      }
      return results;
    }
    return function (items) {
      var length = 0 + items.length;
      var eol = '\r\n';

      var _columns = {};
      for (var i = 0; i < length; i += 1) {
        assign(_columns, items[i]);
      }
      var columns = keys(_columns);

      var empty = {};
      columns.forEach(function(key) {
        empty[key] = '';
      });

      var rows = [];
      for (var i = 0; i < length; i += 1) {
        var row = assign({}, empty, items[i]);
        rows.push(mapObject(row, function(value) {
          value = String(value); // eslint-disable-line no-param-reassign
          if (value.indexOf('"') !== -1) {
            return '"' + value.replace(/"/g, '""') + '"';
          }
          return '"' + value + '"';
        }).join(','));
      }

      return columns.join(',') + eol + rows.join(eol);
    };
  })();

function generalfunctions_escapeHtml(html) {
    const el = document.createElement('div');
    el.textContent = html;
    return el.innerHTML;
}

function generalfunctions_getUserMessageFromError(err) {
    if (err && typeof err.userMessage === 'string') {
        return generalfunctions_escapeHtml(err.userMessage.trim())
            .replace(/\[([\w\s]+?)\]\((.*?)\)/g, function (match, text, url) {
                return '<a href="' + new URL(url).toString() + '" target="_blank" rel="noopener noreferrer">' + generalfunctions_escapeHtml(text) + '</a>';
            })
            .replace(/\n/g, '<br>');
    }
}

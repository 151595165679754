/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by chris on 6/03/15.
 */


ppxControllers.controller('ActivityFeed', function($scope, $rootScope, $routeParams, $location, $sce, UserService, DataService, $cacheRegistry, $interval, $api, $window, SegmentIo, shortcutService, proofInfoService, browserService, $webSocket, $translate, Activity, domService, $q, utilService, sdk, PPProofStatus, PPProofType) {

    $scope.application = UserService; //make the application class available to this scope

    var userData = $scope.application.GetUserData();

    $scope.email = userData["emailAddress"];
    $scope.userId = userData["userId"];

    $scope.activityService = Activity;

    $scope.activityData = $scope.activityService.getData();

    $scope.activityCss = "";
    $scope.activityLoaded = false;

    $scope.email = userData["emailAddress"];
    $scope.userId = userData["userId"];

    $scope.users = new Users($scope.application);

    $scope.activity = [];
    $scope.filteredActivity = [];
    $scope.activityCount = 0;
    $scope.activityMessageCount = 0;
    $scope.isIniConnection = false;
    $scope.actionedNotifications = [];
    $scope.activeTab = 'all';
    $scope.filterBy = null;

    $scope.messageStubs = {

        ProofUserFinished: {iconCss:"green-dot", translateStr:"dashboard.activity.message.ProofUserFinished", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //screen shot

        WorkflowUserAdd: {iconCss:null, translateStr:"dashboard.activity.message.WorkflowUserAdd", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:false}, //donesky

        WorkflowStepActive: {iconCss:null, translateStr:"dashboard.activity.message.WorkflowStepActive", required:["Title", "VersionNumber", "StepTitle", "ProofId"], requiredObject:["Proof", "Proof","WorkflowStep","Proof"], active:true},

        ProofApproved: {iconCss:null, translateStr:"dashboard.activity.message.ProofApproved", required:["UserId","Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //screen shot

        ProofManualApproval: {iconCss:null, translateStr:"dashboard.activity.message.ProofManualApproval", required:["UserId","Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //screen shot

        ProofManualApprovalByApprover: {iconCss:null, translateStr:"dashboard.activity.message.ProofManualApprovalByApprover", required:["UserId","Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'your-decisions'}, //screen shot

        SentToDosProofOwner: {iconCss:null, translateStr:"dashboard.activity.message.SentToDosProofOwner", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //screen shot

        SentToDosEditor: {iconCss:null, translateStr:"dashboard.activity.message.SentToDosEditor", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //

        ProoferFinished: {iconCss:"green-dot", translateStr:"dashboard.activity.message.ProoferFinished", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true, category: 'your-decisions'}, //

        EditorNewVersion: {iconCss:null, translateStr:"dashboard.activity.message.EditorNewVersion", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof","Proof"], active:true},

        UserActivated: {iconCss:"star", translateStr:"dashboard.activity.message.UserActivated", eventStr:"--UserId-- has joined PageProof", required:["UserId"], requiredObject:["User"], active:true}, //

        CommentAdd: {iconCss:"reply", translateStr:"dashboard.activity.message.CommentAdd", required:["UserId", "Page", "Title", "VersionNumber", "ProofId", "CommentId"], requiredObject:["", "Comment", "Proof", "Proof", "Proof", "Comment"], active:true, category: 'attention-needed'}, //user,comment,proof, proof

        Nudged: {iconCss:null, translateStr:"dashboard.activity.message.Nudged", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //

        Skipped: {iconCss:"red-dot", translateStr:"dashboard.activity.message.Skipped", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true}, //

        WithFinalApprover: {iconCss:"lock", translateStr:"dashboard.activity.message.WithFinalApprover", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //

        BeforeFinalApprover: {iconCss:null, translateStr:"dashboard.activity.message.BeforeFinalApprover", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //

        ProofDue: {iconCss:null, translateStr:"dashboard.activity.message.ProofDue", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true},

        NotifyWorkflowAdmin: {iconCss:null, translateStr:"dashboard.activity.message.NotifyWorkflowAdmin", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true},

        NotifyCoOwners: {iconCss:null, translateStr:"dashboard.activity.message.NotifyCoOwners", required:["Title", "VersionNumber", "ProofId"], requiredObject:["Proof", "Proof", "Proof"], active:true},

        ProofDownloaded: {iconCss:"download", translateStr:"dashboard.activity.message.ProofDownloaded", required:["UserId","Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //screen shot

        WithApprover: {iconCss:"lock", translateStr:"dashboard.activity.message.WithApprover", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //

        ProofUnlock: {iconCss:"unlock", translateStr:"dashboard.activity.message.ProofUnlock", required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true}, //

        InvitedToProof: {iconCss:null, translateStr: null, required:["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'attention-needed'}, //

        BeenMentioned: {iconCss:'mention', translateStr:"dashboard.activity.message.BeenMentioned", required:["UserId", "Title", "VersionNumber", "ProofId", "CommentId"], requiredObject:["", "Proof", "Proof", "Proof", "Comment"], active:true, category: 'attention-needed'}, //

        ReplyToLocker: {iconCss:null, translateStr:"dashboard.activity.message.ReplyToLocker", required:["UserId", "Title", "VersionNumber", "ProofId", "CommentId"], requiredObject:["", "Proof", "Proof", "Proof", "Comment"], active:true, category: 'attention-needed'},

        RevertApproval: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertApproval", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true},

        RevertApprovalOwners: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertApprovalOwners", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true},

        RevertApprovalWorkflowUser: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertApprovalWorkflowUser", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'attention-needed'},

        InvitedToBrief: {iconCss: null, translateStr: "dashboard.activity.message.InvitedToBrief", required: ["UserId", "Title", "ProofId"], requiredObject: ["", "Proof", "Proof"], active: true, category: 'attention-needed'},

        AssignBriefToEditor: {iconCss: null, translateStr: "dashboard.activity.message.AssignBriefToEditor", required: ["UserId", "Title", "ProofId"], requiredObject: ["", "Proof", "Proof"], active: true, category: 'attention-needed'},

        RevertTodo: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertTodo", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'your-decisions'},

        RevertTodoOwners: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertTodoOwners", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true},

        RevertTodoLocker: {iconCss:"red-dot", translateStr: "dashboard.activity.message.RevertTodoLocker", required: ["UserId", "Title", "VersionNumber", "ProofId"], requiredObject:["", "Proof", "Proof", "Proof"], active:true, category: 'attention-needed'},

        JoinTeam: { iconCss: null, translateStr: "dashboard.activity.message.JoinTeam", required: ["UserId"], requiredObject: ["User"], active: true }

	};

    //events that don't get an info icon for the info pane

    $scope.hideInfoEvents = ['UserActivated'];

    $scope.showAvatarEvents = ['UserActivated', 'BeenMentioned', 'JoinTeam'];

    $scope.goToCommentEvent = ['BeenMentioned', 'CommentAdd', 'ReplyToLocker'];

    $scope.hasCleared = false;

    $scope.toggleActivity = function(useScrollTo, forceOpen){
        if($scope.activityCss == "active" || forceOpen === false){
            $scope.activityCss = "";
            $scope.direction = "left";
            $scope.marginLeft = "0";
            $scope.resetActivityObj();

            console.log("openned toggle");

            if( $scope.showGotItBtn ) $scope.showGotItBtn = false;
        }else{
            $scope.activityCss = "active";
            $scope.direction = "right";
            $scope.marginLeft = "4";
            if (useScrollTo || $scope.activityService.getData().count > 0) $scope.scrollToTop();
            $scope.activityCount = 0;
            $scope.activityService.addActivityCount($scope.activityCount);
            $scope.hasCleared = true;
            $scope.activity.forEach(function (activity) {
                if (!activity.showAvatar && !activity.thumbnail && activity.proofId) {
                    sdk.files.thumbnail(activity.original.Proof.FileId).then(function (blob) {
                        activity.thumbnail = URL.createObjectURL(blob);
                    })
                }
            })
        }

        if (!$scope.$$phase) $scope.$apply();
    };

    $scope.showNoNewMessage = false;
    $scope.showLearnMessage = false;

    $scope.hasCalledLoadMore = 0;
    $scope.marginLeft = "0";

    $scope.openInfoPane = function(proofId){
        proofInfoService.open(proofId);
        $scope.toggleActivity(false, false);
    };

    $scope.scrollToTop = function() {
        domService.scrollTo('#notifications-start', 300, -120);
    };

    $scope.userClickedOnNotification = function($event, notification) {
        $scope.setActionedNotification(notification);
        if (angular.element($event.target).parents('pp-avatar,button').length) {
            return;
        }
        if (notification.proofId) {
            if (notification.original.Proof.Type !== PPProofType.BRIEF && notification.original.Proof.Status === PPProofStatus.NEW) {
                $scope.openInfoPane(notification.proofId);
            } else if (inArray($scope.goToCommentEvent, notification.title) && notification.original.Comment.CommentId) {
                $location.url('proof-screen/' +notification.proofId+ '?commentId=' +notification.original.Comment.CommentId);
            } else if ($location.path().indexOf(notification.proofId) === -1) { // If not same proof, then only redirect to proof page
                $location.url('proof-screen/' + notification.proofId);
            }
            $scope.toggleActivity(false, false);
        }
    };

    $scope.generateToggle = function(notification) {
        return function toggleNotification() {
            $scope.toggleActionedNotification(notification);
            $scope.setFilteredActivity();
        }
    };

    $scope.toggleActionedNotification = function(notification) {
        var actionedArray = $scope.actionedNotifications || [];
        if ($scope.isActionedNotification(notification)) {
            actionedArray.splice(actionedArray.indexOf(notification.hash), 1);
        } else {
            actionedArray.unshift(notification.hash);
            actionedArray = actionedArray.slice(0, 100);
        }
        window.__pageproof_quark__.localStorage.set('pageproof.app.notifications.actioned', actionedArray);
    };

    $scope.setActionedNotification = function(notification) {
        var actionedArray = $scope.actionedNotifications || [];
        if (actionedArray.indexOf(notification.hash) === -1) {
            actionedArray.unshift(notification.hash);
            actionedArray = actionedArray.slice(0, 100); // maximum of 100 actioned ids (to save space)
            window.__pageproof_quark__.localStorage.set('pageproof.app.notifications.actioned', actionedArray);
            $scope.actionedNotifications = actionedArray;
        }
    };

    $scope.updateActionedNotifications = function(newValues) {
        $scope.actionedNotifications = newValues || [];
    };

    $scope.isActionedNotification = function(notification) {
        return $scope.actionedNotifications.indexOf(notification.hash) !== -1;
    };

    $scope.isNextMonth = function(index) {
        var current = $scope.activity[index],
            previous = $scope.activity[index - 1];
        if (current && previous) {
            return current.timeDateMonthDisplay !== previous.timeDateMonthDisplay;
        }
        return false;
    };

    //reset the activity back to default

    $scope.resetActivityObj = function(){
        if($scope.activity.length > 0){
            var i = 0;
            while(i < $scope.activity.length){
                $scope.activity[i]["showBreak"] = false; //hide all the new message breaks
                i++;
            }

            if(isset($scope.activity[0])) $scope.activity[0]["showBreak"] = true; //show the top new message break
            if(isset($scope.activity[0])) $scope.activity[0]["showTopBorder"] = false; //hide the top border for the first one...
            if(isset($scope.activity[1])) $scope.activity[1]["showTopBorder"] = true; //and show the second one
            $scope.firstBackTrackFired = true;

            if (!$scope.$$phase) $scope.$apply();
        }
        $scope.hasCleared = false;
    };

    $scope.direction = "left"; //left arrow closed by default

    //because there is a slight chance that the server could return duplicates, we need this function

    $scope.isUnique = function(title, message, timestamp){
        var i = 0;
        var is = true;
        while(i < $scope.activity.length){
            if($scope.activity[i]["title"] == title && $scope.activity[i]["message"] == message && $scope.activity[i]["timestamp"] == timestamp){
                is = false;
                break;
            }
            i++;
        }
        return is;
    };

    $scope.hasBreakAlreadyShowen = function(){
        var i = 0;
        var is = false;
        while(i < $scope.activity.length){
            if($scope.activity[i]["showBreak"] == true){
                is = true;
                break;
            }
            i++;
        }
        return is;
    };

    $scope.activityIsValid = function(eventType){
        if( ( ! $scope.messageStubs[eventType] ) || ( $scope.messageStubs[eventType].active == false ) ){
            return false;
        }else{
            return true;
        }
    };

    $scope.preProcessMessages = function(messages){
        var indexsToDelete = [];
        messages.forEach(function(element, index) { //walk the mes array to process each message
            if ( !$scope.activityIsValid(element["EventType"]) ) {
                indexsToDelete.push(index);
            }
        });
        if(indexsToDelete.length > 0){
            indexsToDelete.forEach(function(element, index){
                if (index > -1) {
                    messages.splice(index, 1);
                }
            });
        }
        return messages;
    };

    //handles all incoming messages from the server

    $webSocket.setMessageHandler(function(message){
        $scope.activityLoaded = true;

        var date = new DateTime();

        $scope.loadMore_pressed = false;

        if(message != "OK"){ //OK message get returns on successful connection.

            var mes = $scope.preProcessMessages(message);

            var lastIndx = mes.length - 1;

            var mesLen = mes.length;

            console.log("mesLen:", mesLen);

            if(mesLen > 0){

                $scope.showLoadMoreBtn = true;

                $scope.showLearnMessage = false; //turn off the learn more message

                $scope.showNoNewMessage = false;

                $scope.showGotItBtn = false;

                var i = 0;

                console.log("mes", mes);

                mes.forEach(function(element, index){ //walk the mes array to process each message

                    if( element["MessageType"] != null ){
                        $scope.lastTimeStamp = element["Timestamp"]; //lastTimeStamp records the date/time of the last message so when the user clicks 'load more' the server responds with older messages
                        $scope.activityService.addActivityTimestamp(element["Timestamp"]);
                    }

                    var hash = utilService.sha512(JSON.stringify(element)).substr(0, 16);

                    $scope.parseMessageStr(element["EventType"], element["FromUserEmail"], element).then(function(data){

                        var translatedMessage = data.Translation;
                        var eventType = data.EventType;
                        var timestamp = data.Timestamp;
                        var messageType = data.MessageType;
                        var proofId = data.ProofId;
                        var fromUserId = data.FromUserId;
                        var iconCss = data.IconCss;
                        var timeDate = moment(moment.utc(data.Timestamp).toDate());

                        if(translatedMessage != "" && $scope.isUnique(eventType, translatedMessage, timestamp) ) { //if translatedMessage is empty it means it could be found in the appropriate lang.json file

                            var visible = true; //message is visible

                            var showBreak = false; //some the new message break

                            var showTopBorder = true; //show the top border on the messages


                            //record the first time a BackTrack type is returned, and show the new message break

                            if( messageType == "BackTrack" && $scope.firstBackTrackFired == false){ //messageType //$scope.activity.length == 0
                                if(!$scope.hasBreakAlreadyShowen()) showBreak = true;
                                $scope.firstBackTrackFired = true;
                            }

                            //if the type is BackTrack and its the first element in the array, hide the top border. Otherwise, if its the last item in the mes array, then hide the top border

                            if( messageType == "BackTrack" ){
                                if( index == 0 && $scope.activity.length == 0 ){
                                    showTopBorder = false;
                                }
                            }else{
                                if( lastIndx == index && ( mesLen != 1 ) ){
                                    showTopBorder = false;
                                }
                            }

                            //build the message object

                            var activityObj;

                            if( inArray($scope.hideInfoEvents, eventType) ){
                                proofId = "";
                            }

                            var dateStr;

                            if($routeParams.show_utc_time == "true"){
                                dateStr = date.getFormatedDateStrForComment(timestamp) + " timestamp: " + timestamp;
                            }else{
                                dateStr = date.getFormatedDateStrForComment(timestamp);
                            }

                            var thisYear = moment().year() === timeDate.year();

                            activityObj = {
                                id: $scope.activityMessageCount,
                                hash: hash,
                                title: eventType,
                                message: translatedMessage,
                                timeDate: timeDate,
                                timeDateMonthDisplay: timeDate.format('MMMM') + (thisYear ? '' : ' ' + timeDate.format('YYYY')),
                                time: dateStr,
                                visible: visible,
                                showBreak:showBreak,
                                timestamp:timestamp,
                                showTopBorder:showTopBorder,
                                proofId:proofId,
                                fromUserId:fromUserId,
                                iconCss:iconCss,
                                category: data.Category,
                                original: element,
                            };

                            if ( inArray($scope.showAvatarEvents, eventType)) {
                                activityObj.showAvatar = true;
                            } else if (activityObj.proofId && $scope.activityCss == "active") { // If closed, thumbnails should only be loaded on opening
                                sdk.files.thumbnail(element.Proof.FileId).then(function (blob) {
                                    activityObj.thumbnail = URL.createObjectURL(blob);
                                });
                            }

                            //if the message is a BackTrack type (ie. historical) then add it to the back of the activity array, otherwise new messages go to the front

                            if( messageType == "BackTrack" ){
                                $scope.activity.push(activityObj);
                            }else{
                                $scope.activity.unshift(activityObj);
                            }

                            $scope.activityService.addActivity($scope.activity); //add the activity to the activity service so its retained when the user navigates away

                            //if the activity pane is not open, and ther type isn't BackTrack, then increment the activity count.

                            if ($scope.activityCss != "active" && messageType != "BackTrack") {
                                $scope.activityCount++;
                                if (!$scope.$$phase) $scope.$apply();
                                console.log("activityCount:" + $scope.activityCount);

                            }

                            $scope.activityService.addActivityCount($scope.activityCount); //Add the count to the activityService to retain it for when the user navigates away

                            $scope.activityMessageCount++; //increment the overall count

                            $scope.setFilteredActivity();

                            if (!$scope.$$phase) $scope.$apply();

                        }else{

                            if(translatedMessage == ""){
                                console.warn("translatedMessage is empty.");
                            }else if( ! $scope.isUnique(eventType, translatedMessage, timestamp) ){
                                console.warn("message is not unique");
                            }
                        }

                    });

                });

            } else {

                $scope.showNoNewMessage = true; //show the no new activity message if there nothing new

                //if there's no activity and the user hasn't tried to load more old activity the load more for them

                if ($scope.activityCount == 0 && $scope.hasCalledLoadMore == 0) {
                    $scope.loadMore();

                } else if ($scope.activityCount == 0 && $scope.hasCalledLoadMore == 1 && $scope.activity.length == 0) { //so has tried to load more but there is nothing
                    $scope.showLearnMessage = true; //show the learn more message - check if this is right?
                    $scope.showLoadMoreBtn = false;
                    $scope.showGotItBtn = true;
                } else if ($scope.activityCount == 0 && $scope.hasCalledLoadMore > 1) { //so has tried to load more but there is nothing
                    $scope.showLoadMoreBtn = false;
                }
            }

        }else{
            console.log("websocket connected");
        }
    });

    $scope.showLoadMoreBtn = true;

    $webSocket.setOnLoadHandler(function(){

        //set the defaults for a successful websocket connection

        $scope.isIniConnection = true;
        $scope.activityLoaded = true;
        // $scope.activityCss = "";

    });

    $webSocket.setOnReLoadHandler(function(){

        //set the defaults for a successful websocket connection

        $scope.isIniConnection = true;
        $scope.activityLoaded = true;
        // $scope.activityCss = "";

        $scope.activityCount = 0;
        $scope.hasCalledLoadMore = 0;
        $scope.activityMessageCount = 0;
        $scope.activityService.clearData();

        $scope.activity = [];

    });

    function isValidUserId(userId) {
        return userId && typeof userId === 'string' && userId[0] === 'U' && userId.toUpperCase() === userId;
    }

    /*
     eventType: Eg. BackTrack or null. BackTrack is a historical message that has already been viewed, null is a new message
     fromUserId: The userId of the person who the message relates too. Not all messages have a email in them
     eventDataObj: the message object that gets returned from the server (as a element of the message array) that contains all the activity data
     callbackfunc: just a function
     */

    $scope.parseMessageStr = function(eventType, fromUserEmail, eventDataObj){

        return $q(function(resolve, reject){

            var messageData = $scope.messageStubs[eventType]; //get the message data from the message stubs object

            //if there no message data for the specified type then bail out

            if( ! messageData ){
                reject("nomessagedata");
                return "";
            }

            var translateStr = messageData["translateStr"]; //get the translation key for the translation service

            if (translateStr === null) {
                translateStr = $scope.getTranslateStr(eventType, eventDataObj);
            }

            var iconCss = messageData["iconCss"];

            //if the active activity active flag has been set to false then jump out. I just use this hide redundant testing messages

            //if the active activity active flag has been set to false then jump out. I just use this hide redundant testing messages

            if(messageData["active"] == false){
                reject("inactive");
                return "";
            }

            var required = messageData["required"]; //get what Ids or params is required for the activity message
            var requiredObject = messageData["requiredObject"]; //get what objects are required for the activity message

            $scope.messageVars = {};

            if(required.length > 0){

                if(required[0] == 'UserId') { //if the first one is a userId, (which it always will be if theres an emnail address in the activity message) then load the user.

                    //once the uers loaded then loop through the other required Ids or elements
                    required.some(function (element, index) {

                        if (isset(element)) {

                            var switchTo = "";
                            var requiredObj = requiredObject[index]; //get the right object from the requiredObject array

                            //do some switching arounf of params for a few special cases
                            if (element == 'UserId') {
                                switchTo = fromUserEmail;
                            } else if (element == 'StepTitle') {
                                switchTo = eventDataObj[requiredObj]['Title'];
                                if (switchTo == 'primary_email') {
                                    switchTo = 'proofing (Final approver)';
                                }

                            } else if (element == 'Page') {

                                if( eventDataObj[requiredObj] != null ){
                                    switchTo = eventDataObj[requiredObj][element];
                                }else{
                                    translateStr = "dashboard.activity.message.CommentAddOld";
                                }

                            } else {
                                switchTo = eventDataObj[requiredObj][element];
                            }
                            $scope.messageVars[element] = switchTo; //populate the messageVars object so it can be passed to the $translate service
                        }
                    });

                }else {

                    required.some(function(element, index){

                        if( isset(element) ) {

                            var requiredObj = requiredObject[index];

                            if( ! eventDataObj[requiredObj] ){
                                return true;
                            }

                            if(element == 'StepTitle') {
                                var title = eventDataObj[requiredObj]['Title'];
                                if(title == 'primary_email'){
                                    title = 'proofing (Final approver)';
                                }
                                $scope.messageVars[element] = title;

                            } else if (element == 'Page') {

                                if( eventDataObj[requiredObj] != null ){
                                    $scope.messageVars[element] = eventDataObj[requiredObj][element];
                                }else{
                                    translateStr = "dashboard.activity.message.CommentAddOld";
                                }

                            }else{
                                $scope.messageVars[element] = eventDataObj[requiredObj][element];
                            }

                        }
                    });

                }

                var fromUserId = isValidUserId(eventDataObj.FromUserId) ? eventDataObj.FromUserId : null;

                if (!fromUserId) {
                    if (eventDataObj.Proof) {
                        fromUserId = eventDataObj.Proof.UserId;
                    }
                }

                $translate(translateStr, $scope.messageVars).then(function (translation) {
                    resolve({
                        Translation: translation,
                        EventType: eventDataObj["EventType"],
                        Timestamp: eventDataObj["Timestamp"],
                        MessageType: eventDataObj["MessageType"],
                        ProofId: eventDataObj["Proof"] ? eventDataObj["Proof"]["ProofId"] : null,
                        Deleted: eventDataObj["Deleted"],
                        IconCss: iconCss,
                        FromUserId: fromUserId,
                        Category: messageData.category
                    });
                });
            }
        });

    };

    $scope.parseStrForDisplay = function(eventStr, eventDataObj, requiredObject, element, index){
        var requiredObj = requiredObject[index];
        var repFrom = new RegExp("--" + element + "--", "g");
        var repTo = new RegExp(eventDataObj[requiredObj][element], "g");
        eventStr = eventStr.replace(repFrom, repTo).replace("/g", "").replace("/", "").toString();
        return eventStr;
    };

    $scope.loadMore_pressed = false;

    $scope.loadMoreBtnText = "Show more";

    $scope.showGotItBtn = false;

    $scope.loadMore = function(){
        if($scope.lastTimeStamp == ""){
            console.log("$scope.lastTimeStamp is empty");
            return "";
        }

        if($scope.loadMore_pressed == true){
            console.log("loadMore_pressed");
            return "";
        }

        $scope.loadMoreBtnText = "Show more...";

        $scope.loadMore_pressed = true;

        $scope.hasCalledLoadMore ++;

        $scope.activityService.addLoadMoreCount($scope.hasCalledLoadMore);

        var data = {Timestamp:$scope.lastTimeStamp, message:"BackTrack", Count:$scope.application.GetConfigSetting("ActivityCount")};

        if( $webSocket.hasOpenConnection() ) {
            $webSocket.send(jsonEncode(data));
            $scope.loadMoreBtnText = "Show more";
        }else{
            $webSocket.openSocket("activity"); //reopen if connection is done for some reason
            $scope.loadMoreBtnText = "Show more";
        }

        return true;
    };

    $scope.getTranslateStr = function(eventType, eventDataObj){
  
        switch(eventType) {
            case 'InvitedToProof':
                var reviewStatuses = [PPProofStatus.NEW, PPProofStatus.PROOFING, PPProofStatus.FINAL_APPROVING];
                return translateStr = reviewStatuses.indexOf(eventDataObj.Proof.Status) !== -1
                    ? "dashboard.activity.message.InvitedToProof.review"
                    : "dashboard.activity.message.InvitedToProof.view";
            default:
                return "";
        }
    };

    $scope.init = function(){

        $rootScope.$on('$routeChangeStart', function () {
            $scope.activityCss = '';

            if( !$webSocket.hasOpenConnection() ) {
                $webSocket.openSocket("activity");
            }

            var date = new DateTime();

            if( $scope.activityData["timestamp"] != null){
                $scope.lastTimeStamp = $scope.activityData["timestamp"];
            }else{
                $scope.lastTimeStamp = date.getCurrentUTCDate();
            }

            if( $scope.activityData["loadMoreCount"] > 0 ){
                $scope.hasCalledLoadMore = $scope.activityData["loadMoreCount"];
            }

            $scope.firstBackTrackFired = false;

            if($scope.activityData["activity"].length > 0) {
                $scope.activity = $scope.activityData["activity"];
                $scope.activityMessageCount = $scope.activityData["activity"].length;
                $scope.activityCount = $scope.activityData["count"];
                if (!$scope.$$phase) $scope.$apply();
            }

            $scope.activityLoaded = true;
        });
    };

    $scope.init();

    $scope.$on('notifications-toggle', function() {
        $scope.toggleActivity();
    });

    $scope.$watch('activityCss', function() {
        $rootScope.isActivityFeedOpen = $scope.activityCss == 'active';
    });

    shortcutService.watch('notifications', function() {
        var userId = $scope.application.GetUserData().userId;
        if (userId) {
            $scope.toggleActivity();
        }
    });

    $scope.setActiveTab = function(tab) {
        $scope.activeTab = tab;
        $scope.setFilteredActivity();
    };

    $scope.setFilterBy = function(option) {
        $scope.filterBy = option;
        $scope.setFilteredActivity();
    };

    $scope.setFilteredActivity = function() {
        $scope.filteredActivity = $scope.activity.filter(function(notification) {
            if ($scope.activeTab !== 'all' && notification.category !== $scope.activeTab) {
                return false;
            }
            if ($scope.filterBy !== null) {
                const isRead = $scope.isActionedNotification(notification);
                const doWeWantRead = $scope.filterBy === 'read';
                if (isRead !== doWeWantRead) {
                    return false;
                }
            }
            return true;
        });
    }

    $scope.updateActionedNotifications($window.__pageproof_quark__.localStorage.get('pageproof.app.notifications.actioned'));
    window.__pageproof_quark__.localStorage.watch('pageproof.app.notifications.actioned', $scope.updateActionedNotifications);

});

/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { addEmail, addExternalFile, addWebURL } from '../../components/ProofSetup/utils/addFile';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';
import { sdk } from '../../util/sdk';

export default function ProofSetupByExternal({ initialRouteParams }) {
  useEffect(() => {
    const {
      name,
      file_name: fileName,
      file_url: fileUrl,
      website_url: websiteUrl,
      due_date: dueDate,
      create_email: shouldCreateEmail,
      message_to_reviewers: messageToReviewers,
      reference_id: referenceId,
      reference_label: referenceLabel,
      reference_url: referenceUrl,
      reference_type: referenceType,
      reference_metadata: referenceMetadata,
      previous_proof_id: previousProofId,
    } = initialRouteParams;

    if (previousProofId) {
      Promise.all([
        sdk.proofs.load(previousProofId),
      ]).then(([proof]) => {
        let defaults = null;
        let attachNewVersion = null;
        let initialize = null;

        defaults = {
          name: name || fileName,
          reference: proof.reference,
          tags: proof.tags,
          messageToReviewers: proof.messageToReviewers,
          canDownload: proof.canDownload,
          integrationReferences: proof.integrationReferences,
          isPublic: proof.isPublic,
          commentVisibility: proof.commentVisibility,
          dueDate: proof.usePreviousVersionDueDate ? proof.dueDate : undefined,
        };

        attachNewVersion = {
          proofId: proof.id,
          workflowId: proof.workflowId,
          collectionId: proof.groupId,
          canBePublic: proof.canBePublic,
          canAddChecklist: proof.canAddChecklist,
          availableWorkflowRoles: proof.availableWorkflowRoles,
          canHaveCommentVisibility: proof.canHaveCommentVisibility,
          dueDate: proof.dueDate,
        };

        initialize = {
          checklist: proof.checklist && {
            ...proof.checklist,
            id: undefined,
          },
          workflow: { id: proof.workflowId },
          owners: proof.owners.map(user => user.email),
        };

        requestProofSetup(
          {
            parameters: {
              attachNewVersion,
              defaults,
            },
            initialize,
          },
          () => {
            if (websiteUrl) {
              addWebURL(websiteUrl);
            }
            const { $location } = window.__pageproof_bridge__;

            $location.url('/create');
          }
        );
      });
      return;
    }


    const defaults = {
      name,
      messageToReviewers,
    };

    const file = fileUrl
      ? {
        url: fileUrl,
        name: fileName,
      }
      : undefined;

    if (!Number.isNaN(+dueDate)) {
      defaults.dueDate = new Date(+dueDate);
    }

    // TODO: validate referenceType and support lowered referenceType
    if (referenceId && referenceLabel && referenceType && referenceUrl) {
      defaults.integrationReferences = [{
        referenceId,
        referenceType,
        referenceLabel,
        referenceUrl,
        metadata: referenceMetadata,
      }];
    }

    requestProofSetup({
      parameters: {
        defaults,
        file,
      },
    }, () => {
      if (file) {
        addExternalFile(undefined, file.url, file.name, undefined, undefined, true);
      }

      if (shouldCreateEmail === 'true') {
        addEmail();
      }

      if (websiteUrl) {
        addWebURL(websiteUrl);
      }

      const { $location } = window.__pageproof_bridge__;
      $location.url('/create');
    });
  }, []);

  return null;
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightedText.css';

const HighlightedText = ({ parentText, subtext }) => {
  if (!subtext || subtext.trim() === '') {
    return <span>{parentText}</span>;
  }

  // Escape special characters in subtext for use in regex
  // eslint-disbaled for this line because it does not like the "-" character
  // eslint-disable-next-line no-useless-escape
  const escapedSubtext = subtext.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedSubtext.split(' ').filter(Boolean).join('|')})`, 'gi');
  const parts = parentText.split(regex);

  return (
    <span>
      {parts.map((part, index) => (
        regex.test(part) ? (
          <span
            key={index}
            className={styles.highlighted}
          >
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      ))}
    </span>
  );
};

if (process.env.NODE_ENV !== 'production') {
  HighlightedText.propTypes = {
    parentText: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
  };
}

export default HighlightedText;

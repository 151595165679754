/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './MobileProofPageNavigation.scss';
import IconButton from '../Button/IconButton';
import UnstyledButton from '../Button/UnstyledButton';
import { MobileProofHeaderCenterChild } from '../MobileProofHeaderCenter';

const MobileProofPageNavigation = (props) => {
  const {
    currentPage,
    pageCount,
    onClickPrevious,
    onClickNext,
    onOpenPageGrid,
  } = props;

  const showPreviousArrow = currentPage > 1;
  const showNextArrow = currentPage < pageCount;

  return (
    <MobileProofHeaderCenterChild>
      <div className={css.MobileProofPageNavigation}>
        <IconButton
          src="/img/interface/arrow-upward.svg"
          onClick={onClickPrevious}
          disabled={!showPreviousArrow}
          ariaLabel="Page back"
          className={classnames(
            css.MobileProofPageNavigation__arrow,
            css['MobileProofPageNavigation__arrow--previous'],
            {
              [css['MobileProofPageNavigation__arrow--hidden']]: !showPreviousArrow,
            }
          )}
        />
        <UnstyledButton
          onClick={onOpenPageGrid}
          ariaLabel="Pagination"
        >
          {`${currentPage} / ${pageCount}`}
        </UnstyledButton>
        <IconButton
          src="/img/interface/arrow-upward.svg"
          onClick={onClickNext}
          disabled={!showNextArrow}
          ariaLabel="Page foward "
          className={classnames(
            css.MobileProofPageNavigation__arrow,
            {
              [css['MobileProofPageNavigation__arrow--hidden']]: !showNextArrow,
            }
          )}
        />
      </div>
    </MobileProofHeaderCenterChild>
  );
};

MobileProofPageNavigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onOpenPageGrid: PropTypes.func.isRequired,
};

export default MobileProofPageNavigation;
